// Header.js

import React from 'react';
import { Row,Col,Image,Dropdown} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import './index.scss'; // Import the custom styles

const IconCard = ({icon,heading,value,startTime,endTime,verticleIcon,toolTip}) => {
  return (
    <div className={`icon-card ${verticleIcon}`}>
      <div className='icopnbox'>{icon}</div>
      {
        heading ? <>
        <div className="heading">
        {heading}
        {
          toolTip ? <>
            <Tooltip title={toolTip}>
              <InfoCircleOutlined className='ml-1'/>
            </Tooltip>
          </> : ''
        }
      </div>

        </>: ''
      }
       {
        value ? <>
        <div className="value">
        {value}
      </div>

        </>: ''
      }
       {
        startTime ? <>
        <div className="time">
          <p className="startTime">Start Time :<span>{startTime}</span></p>
          <p className="startTime">End Time :<span>{endTime}</span></p>
        </div>

        </>: ''
      }
      </div>

  );
};

export default IconCard;
