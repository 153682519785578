import { useState, useEffect, useRef} from 'react';
import { Modal } from 'antd';
import { Button } from "Components/ui/button"; 
import { 
    fetchAccountById,
    getTradingNoteOrder,
    getProfile,
    getOneTradingNoteOrder, 
    getOneTradingNotePosition
} from "../../../API";
import { DownloadOutlined } from '@ant-design/icons';
import dayjs from "dayjs";
import html2canvas from 'html2canvas';
import QRCode from "react-qr-code";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import { toPng } from 'html-to-image';

import "./index.css";

const ShareTradeDetailModal = ({isModalOpen, closeModal, accountId, deal, type}) => {
    const [accountData, setAccountData] = useState(null);
    const [profileData, setProfileData] = useState(null);
    const [tradingNoteOrderDetailData, setTradingNoteOrderDetailData] = useState(null);
    // const [qrCodeContent, setQrCodeContent] = useState();
    const basePath = process.env.REACT_APP_BASE_PATH;
    // const qrCodeValue = `${basePath}/purchase-challenge`;
    const [qrCodeValue, setQrCodeValue] = useState("");
    const elementRef = useRef(null);
    // let dataUrl = "";
    // const [imageBase64String, setImageBase64String] = useState("");
    const [inGenerateImage, setInGenerateImage] = useState(false);
   
    const html2canvasDownloadImage = () => {
        const screenShotTarget = document.getElementById("tradeDetailContent");
        setTimeout(() => {
            html2canvas(screenShotTarget, { useCORS: true, scale: 2, allowTaint: true , scrollX:0, scrollY: -window.scrollY}).then((canvas) => {
                const base64image = canvas.toDataURL("image/png");
                let anchor = document.createElement("a");
                anchor.setAttribute("href",base64image);
                anchor.setAttribute("download", "trade_detail_"+new Date().toJSON().slice(0,10)+".png");
                anchor.click();
                anchor.remove();
            });
        }, 200);
    }

    const downloadShareImage = async (e) => {
        setInGenerateImage(true);
        // const screenShotTarget = document.getElementById("tradeDetailContainer");
            
        toPng(elementRef.current, { cacheBust: true })
          .then((dataUrl) => {
            setInGenerateImage(false);
            const link = document.createElement("a");
            link.download = "trade_detail_"+new Date().toJSON().slice(0,10)+".png";
            link.href = dataUrl;
            link.click();
          })
          .catch((err) => {
            console.log(err);
          });
    };

    const getAccountById = async () => {
        try {
            const result = await fetchAccountById(accountId);
            setAccountData(result?.account ?? {});
        } catch(error) {
            console.error('Error fetch Account Data By Id:', error);
        }
    }

    const fetchTradingNoteOrderData = async () => {
        try {
            let result;
            let isSuccess;
            switch(type){
                case "open": 
                    result = await getOneTradingNotePosition(deal, accountId);
                    isSuccess = result?.success ?? false;
                    if (isSuccess) {
                        let orderDetails = result?.position_details ?? {};
                        if (Object.keys(orderDetails).length > 0) {
                            setTradingNoteOrderDetailData(orderDetails);
                        }
                    }
                    break;
                case "closed": 
                    result = await getOneTradingNoteOrder(deal, accountId);
                    isSuccess = result?.success ?? false;
                    if (isSuccess) {
                        let orderDetails = result?.order_details?.answer ?? {};
                        if (Object.keys(orderDetails).length > 0) {
                            setTradingNoteOrderDetailData(orderDetails);
                        }
                    }
                    break;
                default:
                    break;
            }

            // const result = await getTradingNoteOrder(deal, accountId);
            // let isSuccess = result?.success ?? false;
            // if (isSuccess) {
            //     let orderDetails = result?.order_details?.answer ?? {};
            //     console.log(orderDetails    );
            //     if (Object.keys(orderDetails).length > 0) {
            //         setTradingNoteOrderDetailData(orderDetails);
            //     }
            // }
        } catch(error) {
            console.error('Error fetch Trading Note Order:', error);
        }
    }

    const fetchAccountInfo = async () => {
        try {
            const profileResult = await getProfile();
            setProfileData(profileResult);
        } catch (error) {
            console.error("Error fetching user profile data:", error);
        }
    }
    
    useEffect(() => {
        let tempQrCodeValue = `${basePath}/purchase-challenge?ref=${profileData?.user?.myAffiliateCode}`;
        setQrCodeValue(tempQrCodeValue);
    }, [profileData]); 

    useEffect(() => {
        if (isModalOpen) {
            getAccountById();
            fetchTradingNoteOrderData();
            fetchAccountInfo(); 
        }
    }, [isModalOpen]); 

    return (
        <Modal 
            title="Share Trade"
            open={isModalOpen}
            onCancel={closeModal}
            footer={null} 
            maskClosable={false}
        >
            
            <div className='py-3' id="tradeDetailContainer" ref={elementRef}>
                <div        
                    id="tradeDetailContent" 
                    className='relative text-white mb-3 w-[80%] m-auto rounded-xl'
                    style={{ backgroundImage: "url('/images/share_bg.png')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "100% 100%", minHeight: "620px", overflow: "auto" }}
                >
                    <div className='absolute top-0 left-0'>
                        <div className='bg-[#7A0526] rounded-br-xl p-2'>
                            {tradingNoteOrderDetailData?.Symbol ?? ""}
                        </div>
                    </div>
                    <div className='relative mt-9'>
                        <div 
                            className='absolute top-0 right-[1rem] p-2 w-20 h-20 rounded-lg' 
                            style={{ background: "linear-gradient(0deg, rgba(102,102,102,0) 0%, rgba(0,0,0,1) 100%)" }}
                        >
                            <QRCode
                                size={256}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                value={qrCodeValue}
                                viewBox={`0 0 256 256`}
                            />
                            <div className='mt-1 text-[.45rem] text-center'>Scan to Start Your Challenge</div>
                        </div>
                        <div className='flex justify-center items-end mb-4'>
                            <img
                                className="h-10 w-auto pointer-events-none"
                                src="/images/share_trade_logo.png"
                                alt="TradersFlow"
                            />
                        </div>  
                        <div className='relative mx-auto w-2/3'>
                            {(tradingNoteOrderDetailData?.Profit ?? 0) >= 0 && (
                                <img
                                    className="w-full pointer-events-none"
                                    src="/images/share_trade_profit_gain.png"
                                    alt="TradersFlow"
                                />
                            )}
                            {(tradingNoteOrderDetailData?.Profit ?? 0) < 0 && (
                                <img
                                    className="w-full pointer-events-none"
                                    src="/images/share_trade_profit_loss.png"
                                    alt="TradersFlow"
                                />
                            )}
                            
                        </div>
                      
                        <p className='w-fit mx-auto text-white border-white border-solid border-[1px] text-[10px] font-light rounded-sm px-2 py-[.1rem]'>
                            Total Profit & Loss
                        </p>
                        
                        <div className={`profit-amount text-center ${tradingNoteOrderDetailData?.Profit >= 0 ? "gain" : "loss"}`}>
                            {tradingNoteOrderDetailData?.Profit >= 0 && (
                                <p>{"+$" + tradingNoteOrderDetailData?.Profit ?? "0.00"}</p>
                            )}
                            {tradingNoteOrderDetailData?.Profit < 0 && (
                                <p>{"-$" + (Math.abs(tradingNoteOrderDetailData?.Profit)).toFixed(2) ?? "0.00"}</p>
                            )}
                        </div>
                      
                        <div className='flex justify-center items-center'>
                            {tradingNoteOrderDetailData?.Action == "0" && (
                                <div className='ml-3'>
                                    <img
                                        className="h-6 w-auto pointer-events-none"
                                        src="/images/buy_badge.png"
                                        alt="Buy"
                                    />
                                </div>
                            )}

                            {tradingNoteOrderDetailData?.Action == "1" && (
                                <div className='ml-3'>
                                    <img
                                        className="h-6 w-auto pointer-events-none"
                                        src="/images/sell_badge.png"
                                        alt="Sell"
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='grid grid-cols-2 w-[80%] mx-auto mt-3'>
                        <div className='text-center text-xs'>Close Time</div>
                        <div className='text-center text-xs'>Close Price</div>
                    </div> 
                   
                    <div className='grid grid-cols-2 w-[80%] mx-auto'>
                        <div className='bg-black py-2 flex items-center justify-center text-white rounded-l-lg min-h-[62px]'>
                            {type === "closed" && (
                                <div>
                                    <p className='text-base text-center'>{tradingNoteOrderDetailData?.Time
                                        ? dayjs(tradingNoteOrderDetailData?.Time * 1000).format("DD/MM/YYYY")
                                        : ""}</p>
                                    <p className='text-center'>{tradingNoteOrderDetailData?.Time
                                        ? dayjs(tradingNoteOrderDetailData?.Time * 1000).format("hh:mmA")
                                        : ""}</p>
                                </div>
                            )}    
                            {type === "open" && (
                                <div>
                                    <p className='text-base'>-</p>
                                </div>
                            )}  
                        </div>
                        <div className='bg-white py-2 text-[#DD0744] flex items-center justify-center rounded-r-lg'>
                            <p className='text-xl text-center font-semibold break-all'>
                                {type === "closed" && (
                                    tradingNoteOrderDetailData?.Price ? "$"+tradingNoteOrderDetailData?.Price : ""
                                )}
                                {type === "open" && ("-")}
                            </p>
                        </div>
                    </div>
                    <div className='min-h-[58px]'>
                        <div 
                            className='pt-4 pb-5 flex justify-center items-center cursor-pointer'
                            onClick={downloadShareImage} 
                        >
                            <img
                                className="w-[1rem] h-[.85rem] pointer-events-none"
                                src="/images/share_trade_share_icon.png"
                                alt="Share"
                            />
                            <p className='ml-1'>Share</p>
                        </div> 
                    </div>
                </div>
            </div>
            {inGenerateImage && (
                <>
                    <Spin size="large" className='z-[9999]' fullscreen />
                </>
            )} 
        
            {/*  <div className='flex justify-center items-center'>
                    <Button 
                        className="custom-primary-button px-10" 
                        onClick={html2canvasDownloadImage} 
                    >
                        <DownloadOutlined />
                        &ensp;
                    <span>Download Image</span>
                    </Button>
                </div> */}
           
            
        </Modal>
    );
};

export default ShareTradeDetailModal;