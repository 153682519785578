// import Routess from "../../Routes";
import "./index.scss";

import AppRoutes from "../AppRoutes";
import classNames from "classnames";

function PageContent(props) {
  const pageContentClass = props.css ? "PageContent" : "";

  return (
    <div
      className={classNames(`${pageContentClass}`, {
        "!ml-0 !w-auto lg:!ml-[288px] max-w-7xl": props.css,
      })}
    >
      <AppRoutes />
    </div>
  );
}
export default PageContent;
