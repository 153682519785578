import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
const IconCardV2 = ({icon,heading,value,startDate,endDate, toolTip}) => {
    return (
        <div className="bg-white rounded-lg p-[15px] grid gap-1 grid-cols-2 md:grid-cols-1">
            <div 
                className="w-[60px] min-w-[60px] h-[60px] rounded-lg flex items-center justify-center mb-[15px]" 
                style={{background: 'linear-gradient(91deg, #DD0744 0.7%, #FF9A44 143.6%)'}}
            >
                {icon}
            </div>
            <div className='flex flex-col items-end md:items-start'>
                {
                    heading ? <>
                        <div className="text-[#717184] font-normal font-sans font-normal text-base leading-6 mb-[10px]">
                            {heading}
                            {
                                toolTip ? <>
                                    <Tooltip title={toolTip}>
                                        <InfoCircleOutlined className='ml-1'/>
                                    </Tooltip>
                                </> : ''
                            }
                        </div>
                    </> : ''
                }
                {
                    value ? <>
                        <div className="text-[#1D1E25] font-semibold font-inter text-2xl leading-normal">
                            {value}
                        </div>
                    </> : ''
                }
                {
                    startDate? <>
                        <div className="w-full text-[#717184] font-normal font-sans font-normal text-base leading-6 mb-[10px]">
                            <div className=" grid gap-2 grid-cols-1 md:grid-cols-2 ">
                                <div className='flex justify-end md:justify-start items-center'>Start Date</div>
                                <div className='flex justify-end items-center text-[#1D1E25] text-[0.85rem]'>{startDate}</div>
                            </div> 
                        </div>
                    </> : ''
                }
                {
                    endDate ? <>
                        <div className="w-full text-[#717184] font-normal font-sans font-normal text-base leading-6 mb-[10px]">
                            <div className="grid gap-2 grid-cols-1 md:grid-cols-2">
                                <div className='flex justify-end md:justify-start items-center'>End Date</div>
                                <div className='flex justify-end items-center text-[#1D1E25] text-[1.125rem]'>{endDate}</div>
                            </div>  
                        </div>
                    </> : ''
                }
            </div>
        </div>
    );
}

export default IconCardV2;