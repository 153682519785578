// Header.js

import React from 'react';
import { Card, Space, Statistic, Table, Typography,Radio,Row,Col,Button,Image ,Input,Select, Divider,Checkbox,Form} from "antd";

const { Option } = Select;
const CardPaymentForm = ({onFinish}) => {
    return(
        <>
         <Form
    name="card_payment_form"
    onFinish={onFinish}
    initialValues={{ remember: true }}
    layout="vertical"
  >
    <Row gutter={16}>
      <Col span={24}>
        <Form.Item
          label="Card Holder Name"
          name="cardHolderName"
          rules={[{ required: true, message: 'Please enter card holder name' }]}
        >
          <Input  className='input-text h-50' />
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          label="Phone Number"
          name="phoneNumber"
          rules={[{ required: true, message: 'Please enter phone number' }]}
        >
          <Input  className='input-text h-50' />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Country"
          name="country"
          rules={[{ required: true, message: 'Please select country' }]}
        >
          <Select className="input-text ant-select">
            {/* Add country options */}
            <Option value="usa">USA</Option>
            <Option value="canada">Canada</Option>
            {/* Add more options as needed */}
          </Select>
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          label="Zip Code"
          name="zipCode"
          rules={[{ required: true, message: 'Please enter zip code' }]}
        >
          <Input  className='input-text h-50' />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="State"
          name="state"
          rules={[{ required: true, message: 'Please enter state' }]}
        >
          <Input  className='input-text h-50' />
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={16}>
      <Col span={24}>
        <Form.Item
          label="Card Number"
          name="cardNumber"
          rules={[{ required: true, message: 'Please enter card number' }]}
        >
          <Input  className='input-text h-50' />
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          label="Expiration Date"
          name="expirationDate"
          rules={[{ required: true, message: 'Please enter expiration date' }]}
        >
          <Input placeholder="MM/YY"  className='input-text h-50' />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="CVC"
          name="cvc"
          rules={[{ required: true, message: 'Please enter CVC' }]}
        >
          <Input  className='input-text h-50' />
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={16}>
      {/* Images of Visa, MasterCard, American Express, Union Pay */}
      {/* Add your image components here */}
    </Row>

    <Row gutter={16}>
      <Col span={24}>
        <Form.Item name="termsAndConditions" valuePropName="checked">
          <Checkbox >
          I declare that I have read and agree with<span className="color-span"> Terms & Conditions,
            Disclosure Statement</span> & <span className="color-span"> Privacy Policy</span>
          </Checkbox>
        </Form.Item>
      </Col>
    </Row>

    <Form.Item>
      <Button type="primary" className="custom-primary-button h-50" htmlType="submit">
      Confirm Payment
      </Button>
    </Form.Item>
  </Form>
        
        </>

    )
   
 
};

export default CardPaymentForm;
