import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useEffect, useState } from "react";

import AffiliatePortal from "Pages/AffiliatePortal";
import { AuthLayout } from "Pages/auth/Layout";
import Billing from "Pages/Billing";
import Certificate from "Pages/Certificate";
import Competition from "Pages/Competition";
import Dashboard from "Pages/Dashbaord";
import Education from "Pages/Education";
import Faq from "Pages/Faq";
import ForgetPassword from "Pages/auth/emailVerificaton";
import PreSignup from "Pages/auth/emailVerificaton/presignup";
import Matrix from "Pages/Matrix";
import NewChallenge from "Pages/NewChallenge";
import OTP from "Pages/auth/OTP";
import { Outlet } from "react-router-dom";
import PaymentMethod from "Pages/PaymentMethod";
import Payout from "Pages/Payout";
import Reset from "Pages/Reset";
import Signin from "Pages/auth/Signin";
import Signup from "Pages/auth/Signup";
import { Spin, Flex } from "antd";
import TradeDetail from "Pages/TradeDetail";
import TradingJournal from "Pages/TradingJournal";
import TradingPlatform from "Pages/TradingPlatform";
import Profile from "Pages/Profile";
import LoggedInChallengePurchase from "Pages/Challenge/Purchase";
import LoggedInChallengeStatus from "Pages/Challenge/Purchase/Status";
import PurchaseChallenge from "Pages/PurchaseChallenge";
import PaymentFail from "Pages/Payment/Fail";
// import UserForgetPassword from "Pages/auth/ResetPassword";
import { useAuth } from "context/AuthContext";
import UserForgetPassword from "Pages/auth/ForgetScreen";

const unauthorizedOnlyPageList = [
  "/auth/reset-password",
  "/auth/forget-password",
  "/auth/signup",
  "/auth/pre-signup",
  "/auth/otp",
  // "/auth/code",
  "/auth/signin",
];

const notLoggedInPageList = [
  "/purchase-challenge",
];

function AppRoutes() {
  const { user } = useAuth();
  // console.log(user)
  const navigate = useNavigate();
  const location = useLocation();
  const [isInitialized, setIsInitialized] = useState(false);

  const isLoggedIn = () => !!(user || localStorage.getItem("token"));

  useEffect(() => {
    setIsInitialized(false);
    console.log(isInitialized, location.pathname, isLoggedIn());
    // setTimeout(() => {
    if (isLoggedIn() && unauthorizedOnlyPageList.includes(location.pathname)) {
      console.log("redirect to home");
      navigate("/");
    } else if (
      !isLoggedIn() &&
      !unauthorizedOnlyPageList.includes(location.pathname)
    ) {
      if (notLoggedInPageList.includes(location.pathname)) {
        console.log("redirect to:" + location.pathname);
        setIsInitialized(true);
        // navigate(location.pathname);
      } else {
        console.log("redirect to signin");
        navigate("/auth/signin");
      }
      
    } else {
      setIsInitialized(true);
      console.log(location.pathname, "init-ed");
    }
    // console.log(isInitialized, "end")
    // }, 0)
  }, [user, navigate]);

  const AuthorizedOnly = () =>
    isLoggedIn() ? <Outlet /> : <Navigate to="/auth/signin" />;

  return (
    <>
      {!isInitialized ? (
        <>
          <Spin size="large" fullscreen />
        </>
      ) : (
        <Routes>
          {/* {!user ? (<> */}
          {/* <Route path="/" element={<Navigate to="/auth/signin" />} /> */}
          {/* <Route path="/auth/signin" element={<AuthLayout><Signin /></AuthLayout>} /> */}
          {/* </>
            ) : (
              <> */}
          <Route path="/" element={<Dashboard />} />
          <Route path="/matrix" element={<Matrix />} />
          <Route path="/matrix/:id?" element={<Matrix />} />
          <Route path="/trading-journal" element={<TradingJournal />} />
          <Route path="/trading-journal/:id?" element={<TradingJournal />} />
          <Route path="/trade-detail" element={<TradeDetail />} />
          <Route path="/trading-platform" element={<TradingPlatform />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="/payout/:id?" element={<Payout />} />
          <Route path="/payout" element={<Payout />} />
          <Route path="/competition" element={<Competition />} />
          <Route path="/education" element={<Education />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/certificate" element={<Certificate />} />
          <Route path="/certificate/:id?" element={<Certificate />} />
          <Route path="/affiliate-portal" element={<AffiliatePortal />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/payment-method" element={<PaymentMethod />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/new-challenge" element={<NewChallenge />} />

          <Route path="/challenge/purchase" element={<LoggedInChallengePurchase />} />
          <Route path="/challenge/purchase/status/:id?" element={<LoggedInChallengeStatus />} />
          <Route path="/purchase-challenge" element={<PurchaseChallenge />} />
          <Route path="/payment/fail" element={<PaymentFail />} />
          {/* </> */}
          {/* // )} */}

          <Route path="auth" element={<AuthLayout />}>
            <Route path="signin" element={<Signin />} />
            <Route path="signup" element={<Signup />} />
            <Route path="forget-password" element={<ForgetPassword />} />
            <Route path="reset-password" element={<UserForgetPassword />} />
            {/* <Route path="otp" element={<OTP />} /> */}
            <Route path="pre-signup" element={<PreSignup />} />
          </Route>
          <Route path="*" element={<Flex align="center" justify="center" className="h-100 w-100" vertical>Missing Page</Flex>} />
        </Routes>
      )}
    </>
  );
}

export default AppRoutes;
