import {
  DollarCircleOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Card, Space, Statistic, Table, Typography, Radio, Row, Col, Button, Image, Input, Select, Divider,Modal } from "antd";
import { useEffect, useState } from "react";
import axios from 'axios';  // Import axios
import PageHeader from "../../Components/common/pageHeader";
import ChallengeCard from "../../Components/Cards/challengeCard";
import { Checkbox } from 'antd';
import { Link } from "react-router-dom";
import { 
  getAllChallenges,
} from "../../API";
import GenericTable from "../../Components/Tables";

const options = [
  {
    label: 'My Plans',
    value: 'plans',
  },
  {
    label: 'Payment History',
    value: 'paymentHistory',
  },
];
const stepOptions = [
  {
    label: '1 - Step Assessment',
    value: 'step1',
  },
  {
    label: '2- Step Assessment',
    value: 'step2',
  },
];
const swapOptions = [
  {
    label: 'swap',
    value: 'swap',
  },
  {
    label: 'Swap Free(Price+10%)',
    value: 'swapfree',
  },
];

function NewChallenge() {
  const [value4, setValue4] = useState('plans');
  const [value3, setValue3] = useState('step1');
  const [swap, setSwap] = useState('swap');
  const [challengesData, setChallengesData] = useState([]);
  const token = localStorage.getItem('token');

  const [transactions, settransactions] = useState([]);
  useEffect(() => {
    // Function to fetch data from API
    const fetchData = async () => {
      try {
        const storedToken = localStorage.getItem('token');

        const response = await axios.get('https://tfapi-dev.tradersflow.co/api/v1/admin/transactions', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });

        const data = response.data.transactions.data;
        settransactions(data); 
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchData(); // Invoke the fetchData function
  }, []); //


  useEffect(() => {
    
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const challenge = await getAllChallenges();
      setChallengesData(challenge);
      
    };

    fetchData();
  }, []);


  const onChange4 = ({ target: { value } }) => {
    console.log('radio4 checked', value);
    setValue4(value);
  };
  const onChange3 = ({ target: { value } }) => {
    console.log('radio4 checked', value);
    setValue3(value);
  };
  const swapChange = ({ target: { value } }) => {
    setSwap(value);
  };

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const tableColumns = [
    {
      title: 'SN',
      dataIndex: 'sn',
      key: 'sn',
    },
    {
      title: 'Account Size',
      dataIndex: 'accountSize',
      key: 'accountSize',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Transaction ID',
      dataIndex: 'transactionId',
      key: 'transactionId',
    },
    {
      title: 'Transaction Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      key: 'discount',
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
    },
    
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        return <div className={`statusBadge ${status === 'success' ? 'open' : 'close'}`}><span></span>{status}</div>;
      },
    }, 
    {
      title: 'Invoice',
      dataIndex: 'invoice',
      key: 'invoice',
      render: () => {
        return <Button type="primary" className="custom-primary-button"> OPEN</Button>;
      },
    }, 
  ];

  const tableData = transactions.map((withdrawal, index) => ({
    key: index.toString(),
    sn: index + 1,
    accountSize: "1000",
    date: withdrawal.created_at.split('T')[0],
    transactionId: ` ${withdrawal.txn_id? withdrawal.txn_id : ' ID  Not Found'}`,
    transactionType: ` ${withdrawal.description ? withdrawal.description.slice(0, 28) : ''}`,
    amount: withdrawal.amount ? `$ ${parseFloat(withdrawal.amount).toFixed(2)}` : '$ 0.00',
    discount: 0,
    totalAmount: withdrawal.debit_amount ? `$ ${parseFloat(withdrawal.debit_amount).toFixed(2)}` : '$ 0.00',
    status: withdrawal.status === 1 ? 'success' : 'pending',
    invoice: withdrawal.invoice || 'open',
  }));
  
  const tableHeader = 'Trading History';


  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div className="New-Challenge-page">
        <div className="header">
          <PageHeader
            title={'Billing Payment Method '}
          />
          <Radio.Group
            options={options}
            onChange={onChange4}
            value={value4}
            optionType="button"
            buttonStyle="solid"
            className="customradio "
          />
        </div>
        {value4 === 'plans' && (
          <Row gutter={16}>
           <Col className="gutter-row" span={16}>
             <div className="account w-100">
               <div className="package-card-container">
                 {challengesData?.map((challenge) => (
                   <ChallengeCard
                     key={challenge.id}
                     step={challenge.title}
                     image={'images/tLogo.png'} 
                     prize={`$${challenge.price}`}
                     fees={`One Time Fee ${challenge.price}`}
                   />
                 ))}
               </div>
             </div>
           </Col>
           <Col className="gutter-row" span={8}>
             <div className="payment-method-section">
               <h5>Choose Your Payment Method</h5>
               <Radio.Group
               options={stepOptions}
             onChange={onChange3}
             value={value3}
             optionType="button"
             buttonStyle="solid"
             className="customradio "
           />
           <Divider/>
           <h5>Select Your Platform</h5>
           <Radio.Group
               options={['platform']}
             value={'platform'}
             optionType="button"
             buttonStyle="solid"
             className="customradio "
           />
           <Divider/>
 
           <h5>Select Your Broker</h5>
           <Radio.Group
               options={['traderFlow']}
             value={'traderFlow'}
             optionType="button"
             buttonStyle="solid"
             className="customradio "
           />
           <Divider/>
           <h5>Select Your Preference</h5>
           <Radio.Group
               options={swapOptions}
             onChange={swapChange}
             value={swap}
             optionType="button"
             buttonStyle="solid"
             className="customradio "
           />
           
             </div>
             <div className="banner">
               <div className="challangeText">
                 <div className="box">
                   <p>Your Total Price</p>
                   <h1>$99</h1>
                 </div>
                 <div className="list">
                   <p>By Confirming the order</p>
                   <Checkbox onChange={onChange}>I have read all agreements. Privacy, Risk Disclosure, Terms and Condition, Refund Policy</Checkbox>
                   <div className="d-flex enroll-section" gutter={16}>
                     <div>
                       <label>Discount Code</label>
                       <Input type="text" placeholder="Enter your Discount Code"/>
                     </div>
                     <Button type="primary" className="custom-primary-button h-50 mx-auto" onClick={showModal}>
                       <Link to="/payment-method"> Enroll Now</Link>
                       </Button>
                   </div>
                 </div>
               </div>
             </div>
           </Col>
         </Row>
        )}
        {value4 === 'paymentHistory' && (
          <>
            <Row gutter={16}>
              <Col span={24}>
                <div className="table-container">
                  <PageHeader
                  title={'Payment History'}
                  />
                    <GenericTable header={tableHeader} columns={tableColumns} data={tableData} />
                </div>
              </Col>
            </Row>
          </>
        )}

       
      </div>
     
    </>
  );
}

export default NewChallenge;
