import { Carousel, Col, Row } from "antd";

import { Outlet } from "react-router-dom";
import React from "react";

export const AuthLayout = () => {
  const MyCarousel = () => {
    const carouselItemList = [
      {
        image: "/images/sliderbtn.png",
        title: "Take a free tour of our dashboard and unlock its potential!",
        message:
          "Get access to exclusive tour of our platform within 10 seconds,by simply submitting the following information.",
      },
      {
        image: "/images/sliderbtn.png",
        title: "Track your trades on-the-go with easy dashboard access",
        message: "Your dashboard is just a few clicks away!",
      },
    ];
    const contentStyle = {
      margin: 0,
      color: "#fff",
      // height: '160px',
      lineHeight: "3rem",
      textAlign: "center",
      // background: '#364d79',
    };
    return (
      <Carousel autoplay dots dotPosition={"top"}>
        {carouselItemList.map((x, i) => (
          <div className="signin-left-content" key={`${Date.now()}_${i}`}>
            <div className="px-8r" style={{ paddingTop: "2rem" }}>
              <h3
                style={{
                  ...contentStyle,
                  fontSize: "2rem",
                  marginBottom: "2rem",
                }}
              >
                {x?.title}
              </h3>
              <p
                style={{
                  ...contentStyle,
                  fontSize: "1rem",
                  lineHeight: "1.5rem",
                  marginBottom: "3rem",
                }}
              >
                {x?.message}
              </p>
            </div>

            <div className="leftimage">
              <img
                src="/images/dashboardImg.png"
                alt="dashboard-img"
                className="bottom-img"
              />
            </div>
          </div>
        ))}
      </Carousel>
    );
  };
  return (
    <div className="signin-container">
      <Row className="full-height-row">
        <Col xs={0} lg={12} xl={14} className="signin-left">
          {/* <div className="signin-left-content">
                <h2>Take a free tour of our dashboard and unlock its potential!</h2>
                <p>Get access to exclusive tour of our platform within 10 seconds,by simply submitting the following information.</p>
                <img src="/images/sliderbtn.png" alt="Background" className='slider-img' />
                <div className="leftimage">
                  <img src='/images/dashboardimg.png' alt="dashboard-img" className='bottom-img' />
                </div>
              </div> */}

          {MyCarousel()}
        </Col>
        <Col xs={24} lg={12} xl={10} className="signin-right signup-from">
          {/* {children} */}
          <Outlet />
        </Col>
      </Row>
    </div>
  );
};
