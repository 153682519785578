// Header.js

import React from 'react';
import { Row,Col,Image,Dropdown} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './index.scss'; // Import the custom styles
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

const items = [
  {
    label: <a href="https://www.antgroup.com">1st menu item</a>,
    key: '0',
  },
  {
    label: <a href="https://www.aliyun.com">2nd menu item</a>,
    key: '1',
  },
 
  
];


const StatsCard = ({heading,value,slidervalue,toolTip}) => {
  return (
    <div className='stat-card'>
       <div className="heading">
        {heading}
        {
          toolTip ? <>
            <Tooltip title={toolTip}>
              <InfoCircleOutlined className='ml-1'/>
            </Tooltip>
          </> : ''
        }
       </div>
       <div className="value">{value}</div>
       {(slidervalue) &&( <div className="slidervalue">{slidervalue}</div>
)}
    </div>
  );
};

export default StatsCard;
