import React from 'react';
import { Link } from "react-router-dom";
import {
    Row,
    Col,
    Divider,
} from "antd";
import {
    CloseCircleOutlined 
} from "@ant-design/icons";
import { Button } from "Components/ui/button"; 

function PaymentFail() {
    return (
        <div className="purchase-challenge-page px-[40px] py-[30px]">
            <div className='text-xl font-semibold pb-3'>Payment</div>
            <Row gutter={16}>
                <Col className="gutter-row" span={24}>
                    <div className="bg-white rounded-xl">
                        <div className="pt-[20px] pl-[20px] text-[#1D1E25] font-semibold text-lg">
                            <h6>Transaction Status</h6>
                        </div>
                        <Divider />
                        <Row gutter={[16,16]} className='pt-6 pb-12'>
                            <Col className="gutter-row" span={24}>
                           
                                <div className='flex justify-center items-center flex-col'>
                                    <CloseCircleOutlined className='text-[#DD0744] text-6xl'/>
                                    <div className='mt-3 text-center'>Payment Unsuccessful</div>
                                    <div className='mt-6 text-center'>
                                        <div>Remark : If any problem for your transaction,</div>
                                        <div>please contact <a class="text-[#dc0844] hover:text-[#dc0844] hover:underline" href="mailto:support@tradersflow.co">support@tradersflow.co</a></div>
                                    </div>
                                    <div className='flex justify-center items-center mt-4'>
                                        <Link to="/challenge/purchase">
                                            <Button 
                                                className="custom-primary-button px-10" 
                                            >
                                                <span>Proceed</span>
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default PaymentFail;