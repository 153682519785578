// Countdown.jsx

import React, { useEffect, useState } from 'react';
import './index.scss';

const CountdownBox = ({ value, animate }) => {
  return <div className={`countdown-box ${animate ? 'flip' : ''}`}>{value}</div>;
};
const Icon =()=>{
  return(
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="32" viewBox="0 0 9 32" fill="none">
<g opacity="0.5">
<circle cx="4.36364" cy="4.36364" r="4.36364" fill="white"/>
<circle cx="4.36364" cy="27.6366" r="4.36364" fill="white"/>
</g>
</svg>
)
}
const Countdown = ({ countdownTime, gmt }) => {
  const [countdown, setCountdown] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [prevSeconds, setPrevSeconds] = useState(0);
  const [prevMinutes, setPrevMinutes] = useState(0);

  useEffect(() => {
    // ... (previous code)

    const calculateTimeRemaining = () => {
      const eventTime = new Date(countdownTime + ' GMT' + gmt).getTime();
      const currentTime = new Date().getTime();
      const timeDifference = eventTime - currentTime;
    
      if (timeDifference > 0) {
        const totalHours = Math.floor(timeDifference / (1000 * 60 * 60));
    
        const hours = totalHours % 24; // Ensure hours are in the range of 0-23
        //const displayHours = hours < 12 ? hours + 12 : hours; // Convert to 12-hour format if needed
        const displayHours = hours;
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    
        setCountdown({ hours: displayHours, minutes, seconds });
    
        if (seconds !== prevSeconds) {
          setPrevSeconds(seconds);
        }
    
        if (minutes !== prevMinutes) {
          setPrevMinutes(minutes);
        }
      } else {
        setCountdown({ hours: 0, minutes: 0, seconds: 0 });
      }
    };
    
    // ... (remaining code)

    const interval = setInterval(calculateTimeRemaining, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [countdownTime, gmt, prevSeconds, prevMinutes]);


  return (
    <div className="countdownCard p-4">
      <div className="header">
        <div className="clock">
          <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
            <path d="M17 2.8335C9.19418 2.8335 2.83334 9.19433 2.83334 17.0002C2.83334 24.806 9.19418 31.1668 17 31.1668C24.8058 31.1668 31.1667 24.806 31.1667 17.0002C31.1667 9.19433 24.8058 2.8335 17 2.8335ZM23.1625 22.0577C22.9642 22.3977 22.61 22.5818 22.2417 22.5818C22.0575 22.5818 21.8733 22.5393 21.7033 22.426L17.3117 19.8052C16.2208 19.1535 15.4133 17.7227 15.4133 16.4618V10.6535C15.4133 10.0727 15.895 9.591 16.4758 9.591C17.0567 9.591 17.5383 10.0727 17.5383 10.6535V16.4618C17.5383 16.9718 17.9633 17.7227 18.4025 17.9777L22.7942 20.5985C23.3042 20.896 23.4742 21.5477 23.1625 22.0577Z" fill="white"/>
          </svg>
        </div>
        {/* <h5>
          Today’s Permitted loss will Reset in
        </h5> */}
        <div className='flex justify-start items-center text-white font-semibold text-[1.438rem] lg:text-[1.2rem] leading-8'>
          Today’s Permitted loss will Reset in
        </div>

      </div>
      <div className="countdown-container px-[0.5rem]">
        <CountdownBox value={countdown.hours} animate={false} />
        <Icon/>
        <CountdownBox value={countdown.minutes} animate={countdown.minutes !== prevMinutes} />
        <Icon/>
        <CountdownBox value={countdown.seconds} animate={countdown.seconds !== prevSeconds} />
      </div>
      <p className='gmt'>Countdown Timezone: {gmt}</p>
    </div>
   
  );
};

export default Countdown;
