import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js/auto';

const PLChart = ({ detailStats }) => {
  useEffect(() => {
    const data = { 
      labels: detailStats.length > 0 ? detailStats.map((x) => x.displayDate) : '',
      datasets: [{
        label: 'Analytics Data',
        data: detailStats.length > 0 ? detailStats.map((x) => parseFloat(x.pnl)) : '', // Include negative values for demonstration
        borderColor: (context) => {
          const values = context.dataset.data;
          const currentIndex = context.dataIndex;

          // Check if the current value is less than zero
          if (values[currentIndex] < 0) {
            // Check if the previous values are also less than zero
            for (let i = currentIndex; i >= 0; i--) {
              if (values[i] >= 0) {
                return '#DD0744'; // Previous values are not less than zero, use red
              }
            }
            return 'green'; // All previous values are less than zero, use green
          } else {
            return '#DD0744'; // Current value is not less than zero, use red
          }
        },
        borderWidth: 3,
        fill: true,
        backgroundColor: (context) => {
          const value = context.dataset.data;
          const gradient = context.chart.ctx.createLinearGradient(0, 0, 0, 300);
          
          if (value < 0) {
            gradient.addColorStop(0, 'rgba(0, 255, 0, 0.50)'); // Gradient start color for profit (green)
            gradient.addColorStop(1, 'rgba(0, 255, 0, 0.00)'); // Gradient end color for profit (transparent)
          } else {
            gradient.addColorStop(0, 'rgba(221, 7, 68, 0.50)'); // Gradient start color for loss (red)
            gradient.addColorStop(1, 'rgba(255, 154, 68, 0.00)'); // Gradient end color for loss (transparent)
          }
          
          return gradient;
        },
      }],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          type: 'category',
        },
        y: {
          beginAtZero: true,
        },
      },
    };

    const ctx = document.getElementById('analyticsLineChart').getContext('2d');

    const myLineChart = new Chart(ctx, {
      type: 'line',
      data: data,
      options: options,
    });

    return () => {
      myLineChart.destroy();
    };
  }, [detailStats]);

  return(
    <canvas id="analyticsLineChart" width="400" height="370"></canvas>
  )
};

export default PLChart;
