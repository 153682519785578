// Header.js

import React from 'react';
import { Row,Col,Image,Dropdown} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './index.scss'; // Import the custom styles


const items = [
  {
    label: <a href="https://www.antgroup.com">1st menu item</a>,
    key: '0',
  },
  {
    label: <a href="https://www.aliyun.com">2nd menu item</a>,
    key: '1',
  },
 
  
];


const UserCard = ({image,name,balance,id,endDate,initialBalance,phase}) => {
  return (
    <div className='user-card'>
       <Row gutter={16} >
        <Col className="gutter-row" span={24}>
          <div className="cardcontainer">
              <div className='intertnal'>
                  <div className="imagesSection">
                    <Image src={image} alt={"icon"}/>
                  </div>
                  <div className="packageInfo">
                      <div className="balance">
                          Balance: <span>{balance}</span>
                        </div>
                        <h1>{name}</h1>
                        <p className='subtitle'>
                        Currently, you have a TradersFlow account
                        </p>
                    </div>
                    </div>
                  <div className="pkgdetail">
                    <div className='packageEssentials'>
                      <div className='info'>
                        <p className='heading'>Initial Balance</p>
                        <p className='detail'>{initialBalance}</p>
                      </div>
                      {/* <div className='info'>
                        <p className='heading'>Package End Date</p>
                        <p className='detail'>{endDate}</p>
                      </div> */}
                      
                      <div className='info'>
                        <p className='heading'>Phase</p>
                        <p className='detail'>{phase}</p>
                      </div>
                    </div>
                  </div>
          </div>
          
        </Col>
      </Row>
        
    </div>
  );
};

export default UserCard;
