import React, { useState } from 'react';
import { Modal } from 'antd';
import { Button } from "Components/ui/button";
import {
    InfoCircleOutlined
  } from "@ant-design/icons";


const WithdrawAttentionModal = ({isModalOpen, closeModal, onOk}) => {
    return (
        <Modal 
            title=""
            open={isModalOpen}
            onCancel={closeModal}
            footer={null} 
            maskClosable={false}
        >
            <div className='p-3'>
                <div className='text-center mb-4'>
                    <InfoCircleOutlined className='text-[#f8bb86] text-[4rem]' />
                </div>
                <div className='leading-6 mb-6'>
                    <p>1. Make sure there are no pending orders or active trades remaining</p>
                    <p>2. You can only withdraw your profits between the 25th and 27th of every month.</p>
                    <p>3. The minimum withdrawal amount is $100.</p>
                    <p>4. You can choose to withdraw funds through Bank Transfer.</p>
                    <p>5. You will receive 70% of the profits, while the remaining 30% will be returned to the company's account.</p>
                    <p>6. If it's your first time making a withdrawal, you are eligible for a refund.</p>
                    <p>7. Please note that Tradersflow cannot assist if you mistakenly provide an incorrect bank account number.</p>
                </div>
                <div className='flex justify-center items-center'>
                    <Button 
                        className="custom-primary-button px-10" 
                        onClick={onOk} 
                    >
                        Ok
                    </Button>
                </div>
            </div>
           
        </Modal>
    );
}

export default WithdrawAttentionModal;