import {
  DollarCircleOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  UserOutlined,
  BankOutlined
} from "@ant-design/icons";
import "./index.css";
import { 
  fetchWithdrawData,
  fetchOneUserWithdraw,
  fetchAccounts,
  fetchMatrix,
  getAccountPayout,
  fetchoneAccount,
  getProfile,
} from "../../API/index";
import axios from "axios";
import { Card, Space, Statistic, Table, Typography,Radio,Row,Col,Image ,Input,Select, Divider, Modal} from "antd";
import { Button } from "Components/ui/button";
import { useEffect, useState } from "react";
import PageHeader from "../../Components/common/pageHeader";
import PayoutCard from "../../Components/Cards/payoutCard";
import { Tabs } from 'antd';
import GenericTable from "../../Components/Tables";
import { Link } from "react-router-dom";
import WithdrawAttentionModal from "../../Components/Modals/withdrawAttentionModal";
import WithdrawFormModal from "../../Components/Modals/withdrawFormModal";

import { useNavigate, useParams } from "react-router-dom";

function Payout() {
  const [withdrawalData, setWithdrawalData] = useState([]);
  const [accountMatrix, setAccountMatrix] = useState([]);
  const [UserWithdraw, setUserWithdraw] = useState([]);
  const [accountData, setAccountData] = useState({});
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [accountPayout, setAccoutPayout] = useState([]);
  const allAccountName = users.map((x) => x.account_login);
  const uniqueAccountName = allAccountName.filter(item => item.startsWith('2'));
  const navigation = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [isAllowWithdraw, setIsAllowWithdraw] = useState(false);

  const [isShowWithdrawAttentionModal, setIsShowWithdrawAttentionModal] = useState(false); 

  const [modal, modalContextHolder] = Modal.useModal();
  const [profileInfo, setProfileInfo] = useState({});
  const navigate = useNavigate();
  
  const openWithdrawAttentionModal = () => { 
    let isError = false;
    let isVerified = profileInfo?.user?.is_verified ?? 0;
        
    const isValidValue = (value) => value !== null && value !== undefined && value !== '';

    const fields = [
      profileInfo?.user?.first_name,
      profileInfo?.user?.last_name,
      profileInfo?.user?.email,
      // profileInfo?.user?.mobile,
      profileInfo?.profile?.address,
      profileInfo?.user?.city,
      profileInfo?.profile?.zip,
      profileInfo?.user?.state,
      profileInfo?.user?.country,
      profileInfo?.profile?.dob,
      profileInfo?.profile?.gender,
      profileInfo?.profile?.age
    ];

    isError = fields.some(field => !isValidValue(field));
    
    if (isError || isVerified != 1) {
      modal.warning({
        title: 'Reminder!',
        content: (
          <div>
            <div>Please complete your personal, account information, and KYC verification before submitting a withdrawal request.</div>
          </div>
        ),
        className: "custom-modal-check-verification", 
        okText: 'Go to profile',
        centered: true, 
        okButtonProps: { className: "modal-ok-button" },
        onOk(){
          navigate("/profile"); 
        },
      });
    } else {
      setIsShowWithdrawAttentionModal(true);
    }
  };

  const closeWithdrawAttentionModal = () => {
    setIsShowWithdrawAttentionModal(false);
  };

  const handleOnOkWithdrawAttentionModal = () => {
    setIsShowWithdrawAttentionModal(false);
    setTimeout(function(){
      openWithdrawFormModal();
    }, 100);
  }

  const [isShowWithdrawFormModal, setIsShowWithdrawFormModal] = useState(false);
  const openWithdrawFormModal = () => {
    setIsShowWithdrawFormModal(true);
  };

  const closeWithdrawFormModal = (rerunApi) => {
    setIsShowWithdrawFormModal(false);
    if (rerunApi) {
      setIsAllowWithdraw(false);
      fetchUserMatrix();
    }
  };


  useEffect(() => {
    const fetchUserid = async () => {
      try {
        const accountsData = await fetchAccounts();
        setUsers(accountsData);
        const realAccount = accountsData.filter(item => item.account_login.startsWith('2'));
        setSelectedUser(realAccount[0]?.account_login || null);
        //setSelectedUser(optionList || null);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    const fetchDataAndSetProfileData = async () => {
      try {
          const data = await getProfile();
          setProfileInfo(data);
      } catch (error) {
          console.error('Error fetching profile data:', error);
      }
  };

    fetchUserid();
    fetchDataAndSetProfileData(); 
  }, []);

  const fetchUserMatrix = async () => {
    if (selectedUser) {
      try {
        const matrixData = await fetchMatrix(selectedUser);
        setAccountMatrix(matrixData);
      } catch (error) {
        console.error("Error fetching matrix data:", error);
      }
    }
  };

  useEffect(() => {
    fetchUserMatrix();
  }, [selectedUser]);

  const fetchUserAccount = async () => {
    if (accountMatrix) {
      let account_id = accountMatrix?.account_id ?? null;
      if (account_id) {
        try {
          const accountData = await fetchoneAccount(account_id);
          setAccountData(accountData);
          setIsAllowWithdraw(true);
        } catch (error) {
          console.error("Error fetching Account data:", error);
        }
      }
    }
  }

  useEffect(() => {
    fetchUserAccount();
  }, [accountMatrix]);

  useEffect(() => {
    // const fetchData = async () => {
    //   const accountsData = await fetchWithdrawData();
    //   setWithdrawalData(accountsData)
    //   ;
    // };
    // fetchData();
    //fetchUserWithdraw()

    const fetchPayoutData = async (acc_id) => {
      try {
        const payoutData = await getAccountPayout(acc_id);
        setAccoutPayout(payoutData);
      } catch (error) {
        console.error("Error fetching payout data:", error);
      }
    }

    const account_id = accountMatrix?.account_id;
    if (account_id) {
      fetchPayoutData(account_id);
    }

  }, [accountMatrix, id]); 

  const user = JSON.parse(localStorage.getItem('user'));

  // const fetchUserWithdraw = async () => {
  //   const accountsData = await fetchOneUserWithdraw(id);
  //   setUserWithdraw(accountsData);
  //   console.log("one user withdaraw",accountsData);
  // }

  
  const countdownTime = 'Feb 4, 2024 12:00:00'; // Example time, update it with your desired time
  const gmt = '+00:00'; // Example GMT, update it with your desired GMT


  const tableColumns = [
    {
      title: 'Sn',
      dataIndex: 'sn',
      key: 'sn',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    // {
    //   title: 'Method',
    //   dataIndex: 'method',
    //   key: 'method',
    // },
    {
      title: 'Credit Amount',
      dataIndex: 'credit_amount',
      key: 'credit_amount',
    },
    {
      title: 'Profit Amount',
      dataIndex: 'user_profit',
      key: 'user_profit',
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        console.log('this is status',status);
        return <div className={`statusBadge ${status === 'Approved' ? 'open' : (status === 'Rejected' ? "close" : "pending")}`}><span></span>{status}</div>;
      },
    },
    // {
    //   title: 'Payment Info',
    //   dataIndex: 'paymentInfo',
    //   key: 'paymentInfo',
    //   render: () => {
    //     return <Button type="primary" className="custom-primary-button br-7"> OPEN</Button>;
    //   },
    // }, 
  ];
  const tableData = accountPayout?.transactions?.data.map((withdrawal, index) => (
    {
    key: index.toString(),
    sn: index + 1,
    date: withdrawal.created_at.split('T')[0],
    //method: withdrawal.payout_method,
    credit_amount: `$ ${withdrawal?.credit_amount ?? "0.00"}`,
    user_profit: `$ ${withdrawal?.user_profit ?? "0.00"}`,
    currency: withdrawal.currency_unit,
    status: withdrawal.status === 1 ? 'Approved' : (withdrawal.status === 3 ? "Rejected" : "Pending"),
    // status: withdrawal.status === 1 ? 'Approved' : 'Rejected',
    invoice: withdrawal.invoice || 'open', 
  }));

  const handleUserChange = (value) => {
    setSelectedUser(value);
    setIsAllowWithdraw(false);
    navigation( `/payout/${value}`);    

  };
  
  const tableHeader = 'Trading History';

  return (
    <>
      <div className="Payout-page">
        {/* <div className="header">
          <div className="left-section"> */}
        <div className="flex justify-between items-center flex-col sm:flex-row mb-4">
          <div className="w-full flex gap-x-4 gap-y-4 justify-between items-center xl:gap-[20px] sm:justify-start mb-2 sm:mb-0">
            <PageHeader
              title={'Payout'}
            />
            <Select
              value={selectedUser}
              onChange={handleUserChange}
              options={uniqueAccountName.map((packageName) => ({
                value: packageName,
                label: packageName,
              }))}  
            >
              {/* {users.map((user) => (
                <Select.Option key={user.id} value={user.account_login}>
                  {user.account_login}
                </Select.Option>
              ))} */}
            </Select>
          </div>

          <Link to="/challenge/purchase" className="w-full sm:w-auto">
            <Button type="primary" className="custom-primary-button br-15 h-full w-full sm:w-auto h-50">Start New Challenge</Button>
          </Link>
        </div>
        <Row gutter={16}>
          <Col xs={24} lg={16}>
            <Row gutter={16}>
              <Col span={24}>
                <PayoutCard withdrawalData={accountPayout}/>
              </Col>
              <Col span={24}>
                <div className="table-container mb-4">
                  <PageHeader
                    title={'Withdrawal History'}
                  />
                  <GenericTable header={tableHeader} columns={tableColumns} data={tableData} />
                </div>
              </Col>
            </Row>
          </Col>

          <Col xs={24} lg={8}>
            <div className="right-container">
              <div className="Request-card">
                {/* <h1>Request Date 27th Dec 2023</h1>
                <p>minimum payment Amount for withdraw is <span>$32</span></p> */}
                <h1>Withdrawal</h1>
                <p>minimum payment Amount for withdraw is <span>$100.00</span></p>
                <Button 
                  className="custom-primary-button" 
                  onClick={openWithdrawAttentionModal} 
                  disabled={!isAllowWithdraw}
                >
                  Withdrawal Request
                </Button>
              </div> 
              <div className="payout-method-card">
                <h1>Payout Methods</h1>
                <p>Looking for more options to pay? No worries! Tap the button below to chat with our team to discover alternative payment methods tailored to your needs</p>
                {/* <div className="icon-container">
                  <ul>
                    <li>
                      <img className="w-[30px] h-[30px]" src="images/tether.png"/>
                      <span>
                        Tether
                      </span>
                    </li>
                    <li>
                      <span>
                        Perfect Money
                      </span>
                    </li>
                    <li>
                      <span>
                        Bant Transfer
                      </span>
                    </li>
                    
                  </ul>
                </div> */}
                <div className="grid gap-3 grid-cols-2">
                  <div className="flex items-center">
                    <img className="w-[22px] h-[22px]" src="/images/tether.png"/>
                    <div className="ml-1">Tether</div>
                  </div>
                  {/* <div className="flex items-center">
                    <img className="w-[22px] h-[22px]" src="images/perfect_money.png"/>
                    <div className="ml-1">Perfect Money</div>
                  </div> */}
                  {/* <div className="flex items-center">
                    <img className="w-[22px] h-[22px]" src="/images/bank_transfer.png"/>
                    <div className="ml-1">Bank Transfer</div>
                  </div> */}
                  {/* <div className="flex items-center">
                    <img className="w-[22px] h-[22px]" src="images/wise.png"/>
                    <div className="ml-1">Wise</div>
                  </div> */}
                </div>
              </div>
              
              {/* <div className="warning">
                <span>
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="40" height="40" rx="10" fill="url(#paint0_linear_1_1912)"/>
                    <path d="M20 30C25.51 30 30 25.51 30 20C30 14.49 25.51 10 20 10C14.49 10 10 14.49 10 20C10 25.51 14.49 30 20 30ZM20.75 24C20.75 24.41 20.41 24.75 20 24.75C19.59 24.75 19.25 24.41 19.25 24L19.25 19C19.25 18.59 19.59 18.25 20 18.25C20.41 18.25 20.75 18.59 20.75 19L20.75 24ZM19.08 15.62C19.13 15.49 19.2 15.39 19.29 15.29C19.39 15.2 19.5 15.13 19.62 15.08C19.74 15.03 19.87 15 20 15C20.13 15 20.26 15.03 20.38 15.08C20.5 15.13 20.61 15.2 20.71 15.29C20.8 15.39 20.87 15.49 20.92 15.62C20.97 15.74 21 15.87 21 16C21 16.13 20.97 16.26 20.92 16.38C20.87 16.5 20.8 16.61 20.71 16.71C20.61 16.8 20.5 16.87 20.38 16.92C20.14 17.02 19.86 17.02 19.62 16.92C19.5 16.87 19.39 16.8 19.29 16.71C19.2 16.61 19.13 16.5 19.08 16.38C19.03 16.26 19 16.13 19 16C19 15.87 19.03 15.74 19.08 15.62Z" fill="white"/>
                    <defs>
                    <linearGradient id="paint0_linear_1_1912" x1="-0.00131911" y1="20.0259" x2="57.9851" y2="20.8525" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#DD0744"/>
                    <stop offset="0.9996" stopColor="#FF9A44"/>
                    </linearGradient>
                    </defs>
                  </svg>
                </span>
                <p>You will be able to withdraw the 15% profit share once you make 5% growth in your TradersFlow Account.</p>
              </div> */}

            </div>
          </Col>
        
        </Row>
      </div>
      
      {modalContextHolder}

      <WithdrawAttentionModal 
        isModalOpen={isShowWithdrawAttentionModal} 
        closeModal={closeWithdrawAttentionModal} 
        onOk={handleOnOkWithdrawAttentionModal} 
      />
       
      <WithdrawFormModal 
        isModalOpen={isShowWithdrawFormModal} 
        closeModal={closeWithdrawFormModal} 
        accountMatrix={accountMatrix} 
        accountData={accountData}
      />
    </>
  );
}


export default Payout;