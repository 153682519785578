// Header.js

import React from 'react';
import { Row,Col,Image,Dropdown,Button,Divider,Input} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import './index.scss'; // Import the custom styles
import  CopyableInput  from '../../common/copyInput';





const AffiliateCard = ({portal,id}) => {
  const basePath = process.env.REACT_APP_BASE_PATH; 
  return (
    <div className='account-card'>
       <Row gutter={16} >
        <Col className="gutter-row" span={24}>
          <div className="cardcontainer">
              {/* <div className="header">
                <h6>Your Affiliate Link</h6>
              </div>
              <Divider/> */}
              <Row className='formcontainer'>
                <Col span={24}>
                  <Row gutter={16} className='pt-3'>
                    <Col span={24}>
                      <div className='input-container'>
                        <label> Affiliate ID</label>
                        <CopyableInput value={id} portal={portal}/>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={16} className='pt-3'>
                    <Col span={24}>
                      <div className='input-container'>
                        <label> Affiliate Link</label>
                        <CopyableInput value={`${basePath}/purchase-challenge?ref=${id}`} portal={portal}/>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
          </div>
        </Col>
      </Row>
        
    </div>
  );
};

export default AffiliateCard;
