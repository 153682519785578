import { Alert, Space } from "antd";
import { Checkbox, Col, Divider, Form, Image, Input, Row, Select } from "antd";
import {
  Discord,
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
} from "../../../Components/icons/index";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";

import { Button } from "Components/ui/button";
import { ForgetPassword } from "../../../API";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/AuthContext";

const UserForgetPassword = () => {
  const { enteredEmail } = useAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState({ message: "", type: "error" });

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onFinish = async (values) => {
    try {
      setFormError({ message: "", type: "error" });
      setIsLoading(true);
      await ForgetPassword(values);
      setFormError({
        message: "Successful!",
        type: "success",
      });
      navigate("/auth/signin");
    } catch (error) {
      console.log(error?.message, error?.response?.data);
      let errorMessage =
        error?.response?.data?.error ||
        error?.response?.data?.errors?.map((x) => x.message)?.join(". ") ||
        error?.response?.data?.message ||
        error?.response?.data?.message ||
        error.message ||
        "Reset password failed";
      setFormError({
        message: errorMessage,
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <>
      <Link to="/auth/signin">
        <Image
          src="/images/logo.png"
          alt="logo"
          className="signinLogo"
          preview={false}
        />
      </Link>
      <div className="signin-right-internal padding-md padding-sm">
        <h1 className="heading">
          Reset Your <span>Password</span>
        </h1>
        <p className="subtitle">Enter your new password</p>
        <Form name="normal_login" onFinish={onFinish} layout="vertical">
          <Row gutter={16}></Row>
          {!!formError?.message && (
            <Space direction="vertical" size={12} className="w-100">
              <Alert
                message={formError.message || ""}
                // description="This is an error message about copywriting."
                type={formError?.type || "error"}
                showIcon
              />
              <div />
            </Space>
          )}

          {/* Country, Phone, Email, Password, Confirm Password Fields */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
                initialValue={enteredEmail}
                >
                <Input
                  readOnly={true}
                  placeholder="Enter your Email Address"
                  className="input-text"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password placeholder="Password" className="input-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="otp"
                label="OTP"
                rules={[
                  {
                    required: true,
                    message: "Please input your OTP!",
                  },
                ]}
              >
                <Input.Password placeholder="OTP" className="input-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("The two passwords do not match!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm Password" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            {/* <Button type="primary" htmlType="submit" className="custom-primary-button w-100" isLoading={isLoading}>
              Submit
            </Button> */}
            <Button
              loading={isLoading}
              className="w-full px-5 py-3 bg-primary-gradient text-white border-0 rounded-2xl text-sm font-medium shadow-none hover:bg-primary-gradient-hover"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div size="middle" className="social-icons">
        <li>
          <a href="#">
            {" "}
            <Instagram />
          </a>{" "}
        </li>
        <li>
          <a href="#">
            {" "}
            <Facebook />
          </a>{" "}
        </li>
        <li>
          <a href="#">
            {" "}
            <Twitter />
          </a>{" "}
        </li>
        <li>
          <a href="#">
            {" "}
            <LinkedIn />
          </a>{" "}
        </li>
        <li>
          <a href="#">
            {" "}
            <Discord />
          </a>{" "}
        </li>
      </div>
      <div className="signin-right-bottom">
        <p>
          Don’t have an account?
          <Link to="/auth/signin">
            <span> Login Now</span>
          </Link>
        </p>
      </div>
    </>
  );
};

export default UserForgetPassword;
