import React from 'react'; 
import './index.scss'; // Import the custom styles

const WelcomeBannerV2 = ({ title, subheading }) => {
    return (
        <div className='Welcome-banner'>
            <div className='py-6 px-2 min-h-200 mx-auto text-center text-white lg:py-10 lg:px-14'>
                <h1 className='text-white leading-normal font-semibold text-[24px] mb-4 md:text-[28px] lg:text-[32px]'>{title}</h1>
                <p>{subheading}</p>
            </div>
        </div>
    );
};

export default WelcomeBannerV2;