import {
  DollarCircleOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Card, Space, Statistic, Table, Typography,Radio,Row,Col,Button,Image ,Input,Select, Divider,Checkbox} from "antd";
import { useEffect, useState } from "react";
import PageHeader from "../../Components/common/pageHeader";
import WelcomeBanner from "../../Components/common/WelcomeBanner";
import StatsCard from "../../Components/Cards/statsCard";
import { Form } from 'antd';
import CardPaymentForm from "../../Components/Forms/CardPaymentForm";
import CryptoPaymentForm from "../../Components/Forms/CryptoPaymentForm";
import OthersPaymentForm from "../../Components/Forms/OthersPaymentForm";
import Steps from "../../Components/Steps";


const options = [
  {
    label: 'Card',
    value: 'card',
  },
  {
    label: 'Crypto Payment',
    value: 'crypto',
  },
  {
    label: 'Others',
    value: 'others',
  },
];


function PaymentMethod() {
  const [value4, setValue4] = useState('card');
  const [paymentMethod, setPaymentMethod] = useState('banktransfer');

  const handlePaymentMethodChange = (value) => {
    setPaymentMethod(value);
    console.log(value);
  };

  const onChange4 = ({ target: { value } }) => {
    console.log('radio4 checked', value);
    setValue4(value);
  };
  
  const onFinish = (values) => {
    console.log('Form values:', values);
    // Add your save logic here
  };

  return (
    <>
    <div className="Matrix-page">
      <Row gutter={16} className="trading-view-section">
        <Col span={16}>
          <div className='account-card'>
            <div className="cardcontainer">
              <div className="header">
                <h6>Trade Detail</h6>
              </div>
              <Divider/>
              </div>
              <Row className="payment-card-page">
                <Col  span={24}  className="d-flex">
                  <Radio.Group
                    options={options}
                    onChange={onChange4}
                    value={value4}
                    optionType="button"
                    buttonStyle="solid"
                    className="customradio "
                  />
                </Col>
                {
                  value4 === 'card' ? 
                  <Col span={24} className="card-payment-section">
                  <CardPaymentForm onFinish={onFinish}/>
                </Col>
                 : value4 === 'crypto' ?
                 <>
                 <Col span={24} className="crypto-payment-section">
                  <CryptoPaymentForm onFinish={onFinish}/> 
                 </Col>
                 </>
                 :
                 <Col span={24} className="others-payment-section">
                    <OthersPaymentForm onFinish={onFinish} paymentMethod={paymentMethod} onPaymentMethodChange={handlePaymentMethodChange}/>
                 </Col>
                }
                </Row>
          </div>
          {value4 === 'crypto' ?
           <Row gutter={16}>
           <Col span={24}>
            <Steps/>
           </Col>
         </Row>
          :""
          
        }
         

        </Col>
        <Col span={8}>
        <div className="banner">
              <div className="challangeText">
                <div className="box">
                  <p>Your Total Price</p>
                  <h1>$99</h1>
                </div>
                <div className="rightSidesection">
                  <div className="internal">
                    <p>Plan</p>
                    <h1>1 - Step Assessment $10,000 🎉</h1>
                  </div>
                  <div className="internal">
                    <p>Platform</p>
                    <h1>$50</h1>
                  </div>
                  <div className="internal">
                    <p>Server</p>
                    <h1>$50</h1>
                  </div>
                  <div className="internal">
                    <p>Account Type</p>
                    <h1>$50</h1>
                  </div>
                  <div className="internal">
                    <p>Price</p>
                    <h1>$50</h1>
                  </div>
                  <Divider/>
                  <div className="internal">
                    <p>Apply Coupon</p>
                    <div className="list">
                      <div className="d-flex enroll-section" >
                        <div>
                          <Input type="text" placeholder="Coupon Code" className="h-50"/>
                        </div>
                        <Button type="primary" className="custom-primary-button h-50 mx-auto" >
                          Apply
                          </Button>
                      </div>
                    </div>
                  </div>
                  <div className="internal">
                    <p>Discount</p>
                    <h1>$50</h1>
                  </div>
                  <div className="internal">
                    <p>Total Amount</p>
                    <h1>$50</h1>
                  </div>
                </div>
              </div>
        </div>
        {value4 === 'others' && (
          <div className="payment-method-receipt">
          <div className='account-card'>
            <div className="cardcontainer">
              <div className="header">
                <h6>Payment Method</h6>
              </div>
              <Divider/>
              </div>
              {paymentMethod === 'banktransfer' ? 
              <>
              <div className="payment-method">
                <h5>Bank Transfer</h5>
                <p>Please make a payment to the following bank account in the amount of the chosen package price.</p>
                <Divider/>
                <div className="bank-details">
                  <div><p>Amount</p> <h6>$ 99.00 [USD]</h6></div>
                  <div><p>Payment Reference</p> <h6>humanlife89@gmail.com</h6></div>
                  <div><p>Account Number</p> <h6>XXXXXXXXXXXX</h6></div>
                  <div><p>ABA/ACH Routing Number</p> <h6>XXXXXXXXX</h6></div>
                  <div><p>Account Type</p> <h6>Checking</h6></div>
                  <div><p>IBAN</p> <h6>AEXXXXXXXXXXXXXXXXXXXXX</h6></div>
                  <div><p>BIC/SWIFT Code</p> <h6>YOUR CODE</h6></div>
                  <div><p>Bank Name</p> <h6>Mashreq Bank PSC</h6></div>
                  <div><p>Beneficiary Name</p> <h6>Trader Flow</h6></div>
                  <div><p>Beneficiary Address</p> <h6>Beneficiary address here</h6></div>
                  <div><p>Email</p> <h6>support@traderflow.com</h6></div>
                </div>
                <Divider/>
                <div className="payment-details">
                  <div className="note-label">
                    <p>
                      <span>Note:</span>
                     It may take up to 3 - 5 business days for your payment to be verified. Once your payment is verified, you will have immediate access to your Challenge account.
                    </p>
                    </div>
                </div>
              </div>
              </>:
              <>
              <div className="payment-method">
                <h5>Perfect Money</h5>
                <p className="wallet-name"> <span>Wallet</span>XXXXXXXXX</p>
                <Divider/>
                <div className="payment-details">
                  <div className="note-label">
                    <p>
                      <span>Note:</span>
                     It may take up to 3 - 5 business days for your payment to be verified. Once your payment is verified, you will have immediate access to your Challenge account.
                    </p>
                    </div>
                </div>
                </div>
              </>
              }
              
          </div>

        </div>

        )}

        


        </Col>

      </Row>
      
    </div>
    </>
  );
}


export default PaymentMethod;
