// Header.js

import React from 'react';
import { Row,Col,Image,Dropdown} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './index.scss'; // Import the custom styles


const items = [
  {
    label: <a href="https://www.antgroup.com">1st menu item</a>,
    key: '0',
  },
  {
    label: <a href="https://www.aliyun.com">2nd menu item</a>,
    key: '1',
  },
 
  
];


const ChallengeCard = ({image,step,prize,fees}) => {
  return (
    <div className='challengeCard'>
       <Row gutter={16} >
        <Col className="gutter-row" span={24}>
          <div className="cardcontainer">
              <div className='intertnal'>
                  <div className="imagesSection">
                    <Image src={image} alt={"icon"}/>
                  </div>
                  <div className="pkgdetail">
                    <div className="packageInfo">
                    <p>
                        {step}
                        </p>
                      <h1>{fees}</h1>
                    </div>
                  </div>
                  
                </div>
                <div className='linkArrow'>
                  {prize} <span>🎉</span>
                </div>
          </div>
          
        </Col>
      </Row>
        
    </div>
  );
};

export default ChallengeCard;
