// Header.js

import React,{useState} from 'react';
import { Row,Col,Image,Dropdown,Collapse} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './index.scss'; // Import the custom styles


const items = [
  {
    label: <a href="https://www.antgroup.com">1st menu item</a>,
    key: '0',
  },
  {
    label: <a href="https://www.aliyun.com">2nd menu item</a>,
    key: '1',
  },
 
  
];
const { Panel } = Collapse;

const AccordianCard = ({question,answer,index}) => {

  const [expandIconPosition, setExpandIconPosition] = useState("end");
  const onPositionChange = (newExpandIconPosition) => {
      setExpandIconPosition(newExpandIconPosition);
  };
  const onChange = (key) => {
      // console.log(key);
  };
  return (
    <div className='accordianCard'>
        <Collapse
          defaultActiveKey={["1"]}
          onChange={onChange}
          expandIconPosition={expandIconPosition}
          expandIcon={({ isActive }) =>
              isActive ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 39 39" fill="none">
                <g clipPath="url(#clip0_1_1211)">
                  <path d="M30.875 21.125H21.125H17.875H8.125V17.875H17.875H21.125H30.875V21.125Z" fill="#DD0744"/>
                </g>
                <defs>
                  <clipPath id="clip0_1_1211">
                    <rect width="39" height="39" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 39 39" fill="none">
            <g clipPath="url(#clip0_1_1216)">
              <path d="M30.875 21.125H21.125V30.875H17.875V21.125H8.125V17.875H17.875V8.125H21.125V17.875H30.875V21.125Z" fill="#DD0744"/>
            </g>
            <defs>
              <clipPath id="clip0_1_1216">
                <rect width="39" height="39" fill="white"/>
              </clipPath>
            </defs>
          </svg>
              )
          }
          >

          <Panel  header={question}  key={index} >
              <div className={"content"}>
                {answer}
              </div>
          </Panel>
        </Collapse>  
    </div>
  );
};

export default AccordianCard;
