import React, { useRef } from 'react';
import { Input, message, Button } from 'antd';
// import { Button } from "Components/ui/button";
import { CopyOutlined } from '@ant-design/icons';
import './index.scss'

const CopyableInput = ({ value,portal }) => {
  console.log('portal',portal)
  const inputRef = useRef(null);

  const copyToClipboard = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand('copy');
      message.success('Copied to clipboard');
    }
  };

  return (
    <div className={`copyableinput ${portal}`} >
      <Input value={value} ref={inputRef} readOnly />
      {!portal && (
        <Button onClick={copyToClipboard} icon={<CopyOutlined />} className='copy-btn' />
      )}
      {portal && (
        <Button className='custom-primary-button'  onClick={copyToClipboard}>  
        <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
          <path d="M20.3601 13.2301C19.9901 13.2301 19.6801 12.9501 19.6401 12.5801C19.4001 10.3801 18.2201 8.40007 16.4001 7.14007C16.0701 6.91007 15.9901 6.46007 16.2201 6.13007C16.4501 5.80007 16.9001 5.72007 17.2301 5.95007C19.4001 7.46007 20.8001 9.82007 21.0901 12.4301C21.1301 12.8301 20.8401 13.1901 20.4401 13.2301C20.4101 13.2301 20.3901 13.2301 20.3601 13.2301Z" fill="white"/>
          <path d="M3.74004 13.28C3.72004 13.28 3.69004 13.28 3.67004 13.28C3.27004 13.24 2.98004 12.88 3.02004 12.48C3.29004 9.86996 4.67004 7.50996 6.82004 5.98996C7.14004 5.75996 7.60004 5.83996 7.83004 6.15996C8.06004 6.48996 7.98004 6.93996 7.66004 7.16996C5.86004 8.44996 4.69004 10.43 4.47004 12.62C4.43004 13 4.11004 13.28 3.74004 13.28Z" fill="white"/>
          <path d="M15.9901 21.6001C14.7601 22.1901 13.4401 22.4901 12.0601 22.4901C10.6201 22.4901 9.2501 22.1701 7.9701 21.5201C7.6101 21.3501 7.4701 20.9101 7.6501 20.5501C7.8201 20.1901 8.2601 20.0501 8.6201 20.2201C9.2501 20.5401 9.9201 20.7601 10.6001 20.8901C11.5201 21.0701 12.4601 21.0801 13.3801 20.9201C14.0601 20.8001 14.7301 20.5901 15.3501 20.2901C15.7201 20.1201 16.1601 20.2601 16.3201 20.6301C16.5001 20.9901 16.3601 21.4301 15.9901 21.6001Z" fill="white"/>
          <path d="M12.05 2.51001C10.5 2.51001 9.22998 3.77001 9.22998 5.33001C9.22998 6.89001 10.49 8.15001 12.05 8.15001C13.61 8.15001 14.87 6.89001 14.87 5.33001C14.87 3.77001 13.61 2.51001 12.05 2.51001Z" fill="white"/>
          <path d="M5.04998 14.3701C3.49998 14.3701 2.22998 15.6301 2.22998 17.1901C2.22998 18.7501 3.48998 20.0101 5.04998 20.0101C6.60998 20.0101 7.86998 18.7501 7.86998 17.1901C7.86998 15.6301 6.59998 14.3701 5.04998 14.3701Z" fill="white"/>
          <path d="M18.9499 14.3701C17.3999 14.3701 16.1299 15.6301 16.1299 17.1901C16.1299 18.7501 17.3899 20.0101 18.9499 20.0101C20.5099 20.0101 21.7699 18.7501 21.7699 17.1901C21.7699 15.6301 20.5099 14.3701 18.9499 14.3701Z" fill="white"/>
        </svg>
        </span>
        Copy</Button>

      )  }
    </div>
  );
};
export default CopyableInput;
