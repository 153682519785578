import React, { useState, useEffect } from 'react';
import { 
    Modal,
    Form,
    Row,
    Col,
    Input,
    Tabs,
    Checkbox,
    notification,
    Select
} from 'antd';
import { Button } from "Components/ui/button"; 
import { 
    submitWithdraw,
    fetchAccountProfit,
} from "../../../API";
import "./index.css";

const { Option } = Select;

const WithdrawFormModal = ({isModalOpen, closeModal, accountMatrix, accountData}) => { 
    const [formWithdraw] = Form.useForm();
    const [toast, contextHolder] = notification.useNotification();

    const [accountProfit, setAccountProfit] = useState(null);
    const [totalProfitAmount, setTotalProfitAmount] = useState("");
    const [isFirstTimeWithdraw, setIsFirstTimeWithdraw] = useState(false);
    const [isFirstTimeRefundable, setIsFirstTimeRefundable] = useState(false);
    const [firstTimeRefundableAmt, setFirstTimeRefundableAmt] = useState(null); 

    const walletNetworks = [
        {
            label: "TRC20",
            value: "TRC20",
        },
        {
            label: "ERC20",
            value: "ERC20",
        },
    ];

    const onSubmitWithdrawForm = async (values) => {
        let postObj = {
            amount: values?.withdraw_amount ?? "",
            payment_method_id: 5,
            account_id: accountMatrix?.account_id ?? "",
            currency_id: 3,
            recipient_address: "",
            auth_code: "",
            competitor: "",
            network_fee: "",
            // account_holder: values?.account_holder_name ?? "", 
            // account_number: values?.bank_account_number ?? "",
            // bank_name: values?.bank_issued ?? "",
            wallet_address: values?.wallet_address ?? "", 
            wallet_network: values?.wallet_network ?? "",
        }   

        try {
            let result = await submitWithdraw(postObj);
            if (result) {
                toast.success({
                    message: "",
                    description: "Request Withdraw Successfully!",
                    placement: "top",
                });
                handleResetFormWithdraw();
                closeModal(true);
            }
        } catch(error) {
            toast.error({
                message: "",
                description: error?.response?.data?.message ?? "Create Withdraw Failed!",
                placement: "top",
            });
            console.error('Error submit form withdraw:', error);
        }
    }

    const handleResetFormWithdraw = () => {
        formWithdraw.resetFields([
            // 'total_profit_amount', 
            'withdraw_amount', 
            // 'first_time_withdraw',
            // 'own_profit',
            // 'share_profit',
            // 'account_holder_name',
            // 'bank_account_number',
            // 'bank_issued',
            'wallet_address', 
            'wallet_network',
        ]);

        formWithdraw.setFieldsValue({
            read_agreement: false, // Set the value of "read_agreement" checkbox to false
        });
    }

    const handleOnInputWithdrawAmount = (e) => {
        // Allow only numeric input by removing non-numeric characters
        e.target.value = e.target.value.replace(/[^0-9.]/g, '');
        
        let inputValue = e.target.value;  
        if (inputValue) {
            inputValue = parseFloat(inputValue);

            let ownProfit = ((70/100) * inputValue);
            let shareProfit = ((30/100) * inputValue);
            formWithdraw.setFieldsValue({
                own_profit: ownProfit.toFixed(2),
                share_profit: shareProfit.toFixed(2),
            });

            let initial_balance = accountMatrix?.initial_balance ?? 0; 
            if (initial_balance > 0) {
                if (inputValue >= ((5/100)*initial_balance) && isFirstTimeWithdraw) {
                    // greater than 5% , refundable challenge price 
                    setIsFirstTimeRefundable(true);
                    formWithdraw.setFieldsValue({
                        refundable_amount: firstTimeRefundableAmt.toFixed(2),
                    });
                } else {
                    setIsFirstTimeRefundable(false);
                    formWithdraw.setFieldsValue({
                        refundable_amount: "",
                    });
                }
            }
        } else {
            formWithdraw.setFieldsValue({
                own_profit: "",
                share_profit: "",
            });
        }
    };

    const handleCloseModal = () => {
        handleResetFormWithdraw();
        closeModal(false); // boolean false = dont re-fetch data at payout page 
    }

    const getAccountProfit = async (accountLogin) => {
        try {
            const profit = await fetchAccountProfit(accountLogin);
            setAccountProfit(profit.profit);
        } catch (error) {
            console.error("Error fetching Account Profit data:", error);
        }
    };

    useEffect(() => {
        if (isModalOpen) {
            let initialBalance = accountMatrix?.initial_balance ?? 0;
            let equity = accountMatrix?.equity ?? 0;
            let balance = accountMatrix?.balance ?? 0;
            let totalWithdrawAmount = accountMatrix?.total_withdraw_amount ?? 0; 
            let accountLogin = accountData?.account?.account_login ?? null;
            let challengePrice = accountData?.user_challenge?.challenge?.price ?? null;
            
            setTotalProfitAmount((balance - initialBalance).toFixed(2));
            setIsFirstTimeWithdraw((totalWithdrawAmount > 0) ? false : true);
            setFirstTimeRefundableAmt(((totalWithdrawAmount == 0) && challengePrice) ? challengePrice : 0);
            if (accountLogin) {
                getAccountProfit(accountLogin);
            }
        }
    }, [isModalOpen]); 
        
    useEffect(() => {
        formWithdraw.setFieldsValue({
            total_profit_amount: accountProfit ?? totalProfitAmount,
        });
    }, [accountProfit, totalProfitAmount]);

    useEffect(() => {
        formWithdraw.setFieldsValue({
            first_time_withdraw: isFirstTimeWithdraw ? "Yes" : "No",
        });
    }, [isFirstTimeWithdraw]);
    
    const tab_items = [
        // {
        //     key: '1',
        //     label: 'Bank Transfer',
        //     children: (
        //         <>
        //             <Row gutter={16}>
        //                 <Col span={24}>
        //                     <Form.Item
        //                         name="account_holder_name" 
        //                         label={<span>Account Holder Name<span style={{ color: 'red' }}> *</span></span>} 
        //                         rules={[
        //                             {
        //                                 required: true,
        //                                 message: "Account Holder Name is required",
        //                             },
        //                         ]}
        //                     >
        //                         <Input 
        //                             placeholder="**Name (Strictly follow Bank Information)" 
        //                             className="input-text h-[50px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent" 
        //                         />
        //                     </Form.Item> 
        //                 </Col>
        //                 <Col span={24}>
        //                     <Form.Item
        //                         name="bank_account_number" 
        //                         label={<span>Bank Account Number<span style={{ color: 'red' }}> *</span></span>} 
        //                         rules={[
        //                             {
        //                                 required: true,
        //                                 message: "Bank Account Number is required",
        //                             },
        //                         ]}
        //                     >
        //                         <Input 
        //                             placeholder="e.g. bank account number" 
        //                             className="input-text h-[50px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent" 
        //                             onInput={(e) => {
        //                                 // Allow only numeric input by removing non-numeric characters
        //                                 e.target.value = e.target.value.replace(/\D/g, ''); 
        //                             }}
        //                         />
        //                     </Form.Item> 
        //                 </Col>
        //                 <Col span={24}>
        //                     <Form.Item
        //                         name="bank_issued" 
        //                         label={<span>Bank Issued<span style={{ color: 'red' }}> *</span></span>} 
        //                         rules={[
        //                             {
        //                                 required: true,
        //                                 message: "Bank Issued is required",
        //                             },
        //                         ]}
        //                     >
        //                         <Input 
        //                             placeholder="e.g. RHB, CIMB" 
        //                             className="input-text h-[50px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent" 
        //                         />
        //                     </Form.Item> 
        //                 </Col>
                        
        //             </Row>
        //         </>
        //     ),
        // },
        {
            key: '1',
            label: 'USDT',
            children: (
                <>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="wallet_network"
                                label="Wallet Network"
                                rules={[
                                    {
                                        required: true,
                                        message: "Wallet Network is required",
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select wallet network"
                                    allowClear
                                >
                                    {walletNetworks.map((network, index) => (
                                        <Option key={network.value} value={network.value}>
                                            {network.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="wallet_address" 
                                label={<span>Wallet Address<span style={{ color: 'red' }}> *</span></span>} 
                                rules={[
                                    {
                                        required: true,
                                        message: "Wallet Address is required",
                                    },
                                ]}
                            >
                                <Input 
                                    placeholder="Wallet Address" 
                                    className="input-text h-[50px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent" 
                                />
                            </Form.Item> 
                        </Col>
                    </Row>
                </>
            ),
        },
    ];

    return (
        <>
            {contextHolder}
            <Modal 
                title={
                    <>
                        <div>Withdraw</div>
                        <hr className='my-2'/>
                    </>
                }
                open={isModalOpen}  
                onCancel={() => handleCloseModal()}
                footer={null} 
                maskClosable={false}
            >
                <Form
                    form={formWithdraw}
                    name="withdraw_form"
                    onFinish={(values) => onSubmitWithdrawForm(values, formWithdraw)}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    requiredMark={false} 
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="total_profit_amount" 
                                label={<span>Total Profit Amount</span>} 
                            >
                                <Input 
                                    placeholder="Total Profit Amount" 
                                    className="input-text h-[50px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent" 
                                    disabled 
                                />
                            </Form.Item> 
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="withdraw_amount" 
                                label={<span>Withdraw Amount<span style={{ color: 'red' }}> *</span></span>} 
                                rules={[
                                    {
                                        required: true,
                                        message: "Withdraw Amount is required",
                                    },
                                    {
                                        validator: (_, value) => {
                                            return new Promise((resolve, reject) => {
                                                if (!value) {
                                                    resolve(); // Allow empty value
                                                } else {
                                                    const floatValue = parseFloat(value);
                                                    if (floatValue < 100) {
                                                        reject('Minimum $100');
                                                    } else {
                                                        const regex = /^\d*\.?\d+$/;
                                                        if (!(regex.test(value))) {
                                                            reject('Please enter a valid Withdraw Amount');
                                                        } else {
                                                            resolve(); // Validation passes
                                                        }
                                                    }
                                                }
                                            });
                                        }
                                    }
                                ]} 
                                
                            >
                                <Input 
                                    placeholder="**Minimum $100" 
                                    className="input-text h-[50px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent" 
                                    onInput={handleOnInputWithdrawAmount}
                                />
                            </Form.Item> 
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="first_time_withdraw" 
                                label={<span>First Time Withdraw(Refundable)</span>} 
                            >
                                <Input 
                                    placeholder="First Time Withdraw(Refundable)" 
                                    className="input-text h-[50px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent" 
                                    disabled
                                />
                            </Form.Item> 
                        </Col>
                        {
                            isFirstTimeRefundable && firstTimeRefundableAmt > 0 && (
                                <Col span={24}>
                                    <Form.Item
                                        name="refundable_amount" 
                                        label={<span>Refundable Amount</span>} 
                                    >
                                        <Input 
                                            placeholder="Refundable Amount" 
                                            className="input-text h-[50px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent" 
                                            disabled
                                        />
                                    </Form.Item> 
                                </Col>
                            )
                        }
                        <Col span={24}>
                            <Form.Item
                                name="own_profit" 
                                label={<span>Own Profit(70%)</span>} 
                            >
                                <Input 
                                    placeholder="Own Profit(70%)" 
                                    className="input-text h-[50px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent" 
                                    disabled
                                />
                            </Form.Item> 
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="share_profit" 
                                label={<span>Share Profit(30%)</span>} 
                            >
                                <Input 
                                    placeholder="Share Profit(30%)" 
                                    className="input-text h-[50px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent" 
                                    disabled
                                />
                            </Form.Item> 
                        </Col>
                    </Row>
                    
                    {/* Tab */}
                    <Tabs defaultActiveKey="1" items={tab_items} />
                    
                    {/* Checkbox read agreement */}
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item 
                                name="read_agreement" 
                                valuePropName="checked" 
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            value ? Promise.resolve() : Promise.reject('Please tick you have read the agreement.'),
                                    },
                                ]}
                            >
                                <Checkbox className="text-black">
                                    I have read the agreements. 
                                    <a href="https://tradersflow.s3.ap-southeast-1.amazonaws.git bcom/TERMS+OF+SERVICE.pdf" target="_blank" rel="noreferrer">
                                        <span className='text-[#DD0744]'> Terms and Condition</span>
                                    </a>
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    
                    {/* Button Submit */}
                    <Row gutter={16}>
                        <Col span={24}>
                            <div className='flex justify-center items-center'>
                                <Button 
                                    type="primary" 
                                    className="custom-primary-button h-[50px] px-12 py-5 rounded-xl"  
                                >
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}

export default WithdrawFormModal;