import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const WinningDoughnutChart = ({
  winPercentage,
  losePercentage
}) => {
  const winRate = winPercentage; // Example win rate value
  const loseRate = losePercentage; // Example lose rate value

  const data = {
    labels: ['Win', 'Lose'],
    datasets: [
      {
        data: [winPercentage, losePercentage],
        backgroundColor: ['#FF9A44', '#DD0744'], // Green for Win, Orange for Lose
        borderColor: '#fff',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '80%', // Set the cutout to adjust the width of the Doughnut (percentage)
   
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || '';
            const value = context.parsed.y || 0;
            return `${label}: ${value}%`;
          },
          title: (tooltipItem) => {
            return `Total: ${tooltipItem[0].raw}%`;
          },
        },
      },
    },
  };

  const chartContainerStyle = {
    position: 'relative',
    width: '236px',
    height: '236px',
    margin:'auto',
    display:'flex',
  };

  const centerText = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: '#000',
    fontSize: '24px',
  };

  const sideInfoStyle = {
    position: 'absolute',
    top: 0,
    right: '90%', // Adjust as needed
    textAlign: 'right',
  };

  return (
    <div className='relative'>
      <div style={chartContainerStyle}>
        <Doughnut data={data} options={options} />
        <div style={centerText}>
          {winPercentage}%
        </div>
        {/* <div style={sideInfoStyle}> */}
        {/* <div style={{marginLeft:'40px',minWidth:'fit-content'}}>   */}
      </div>
      <div className='text-center md:text-left pt-4 md:absolute md:top-0 md:right-[2%] min-w-max'>
          <div style={{color:'#000'}}>
            Win Rate: 

          </div>
          <div style={{ color: '#4CAF50' }}>
            Win Rate: {winRate}%
          </div>
          <div style={{ color: '#FF5733' }}>
            Lose Rate: {loseRate}%
          </div>
        </div>
    </div>
    
  );
};

export default WinningDoughnutChart;
