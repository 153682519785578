// Header.js

import "./index.scss"; // Import the custom styles

import { Button, Col, Divider, Dropdown, Image, Input, Modal, Row } from "antd";
import {
  ChangeInvestorPassword,
  ChangeMasterPassword,
} from "../../../API/index";
import { DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import CopyableInput from "../../common/copyInput";
import { Link } from "react-router-dom";
import React from "react";
import { useState } from "react";

const AccountCard = ({
  image,
  name,
  balance,
  id,
  endDate,
  initialBalance,
  phase,
  account_id,
  server,
}) => {
  const [investorPassword, setInvestorPassword] = useState("");
  const [masterPassword, setMasterPassword] = useState("");
  const [modal, modalContextHolder] = Modal.useModal();
  const [investorPasswordError, setInvestorPasswordError] = useState("");
  const [masterPasswordError, setMasterPasswordError] = useState("");

  const handleMasterPasswordChange = (e) => {
    const newPassword = e.target.value;
    setMasterPassword(newPassword);
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}|:"<>?`\-=[\];',./\\]).{8,}$/;
    if (!passwordPattern.test(newPassword)) {
      setMasterPasswordError(
        "Password must contain at least 8 characters with one uppercase letter, one lowercase letter, one digit, and one special character."
      );
    } else {
      setMasterPasswordError("");
    }
  };

  const resetMasterPassword = async () => {
    if (masterPassword.length === 0) {
      setMasterPasswordError(
        "Password must contain at least 8 characters with one uppercase letter, one lowercase letter, one digit, and one special character."
      );
      return;
    }

    modal.confirm({
      title: "Confirmation",
      icon: <ExclamationCircleOutlined />,
      content: "Confirm to reset master password?",
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,
      okButtonProps: { className: "modal-ok-button" },
      onOk() {
        handleSubmitMasterPassword();
      },
    });
  };

  const handleSubmitMasterPassword = async () => {
    try {
      let result = await ChangeMasterPassword(masterPassword, account_id);
      let updateResultStatus = result?.success;
      if (updateResultStatus) {
        modal.success({
          title: "Update Successfully!",
          content: (
            <div>
              <div>Master password has been updated!</div>
            </div>
          ),
          okText: "OK",
          centered: true,
          okButtonProps: { className: "modal-ok-button" },
          onOk() {
            setMasterPassword("");
          },
        });
      }
    } catch (error) {
      modal.error({
        title: "Error!",
        content: (
          <div>
            <div>{error?.response?.data?.message ?? "Update Failed"}</div>
          </div>
        ),
        okText: "OK",
        centered: true,
        okButtonProps: { className: "modal-ok-button" },
        onOk() {},
      });
    }
  };

  const handleInvestorPasswordChange = (e) => {
    const newPassword = e.target.value;
    setInvestorPassword(newPassword);
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}|:"<>?`\-=[\];',./\\]).{8,}$/;
    if (!passwordPattern.test(newPassword)) {
      setInvestorPasswordError(
        "Password must contain at least 8 characters with one uppercase letter, one lowercase letter, one digit, and one special character."
      );
    } else {
      setInvestorPasswordError("");
    }
  };

  const resetInvestorPassword = async () => {
    if (investorPassword.length === 0) {
      setInvestorPasswordError(
        "Password must contain at least 8 characters with one uppercase letter, one lowercase letter, one digit, and one special character."
      );
      return;
    }

    modal.confirm({
      title: "Confirmation",
      icon: <ExclamationCircleOutlined />,
      content: "Confirm to reset investor password?",
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,
      okButtonProps: { className: "modal-ok-button" },
      onOk() {
        handleSubmitInvestorPassword();
      },
    });
  };

  const handleSubmitInvestorPassword = async () => {
    try {
      let result = await ChangeInvestorPassword(investorPassword, account_id);
      let updateResultStatus = result?.success;
      if (updateResultStatus) {
        modal.success({
          title: "Update Successfully!",
          content: (
            <div>
              <div>Investor password has been updated!</div>
            </div>
          ),
          okText: "OK",
          centered: true,
          okButtonProps: { className: "modal-ok-button" },
          onOk() {
            setInvestorPassword("");
          },
        });
      }
    } catch (error) {
      modal.error({
        title: "Error!",
        content: (
          <div>
            <div>{error?.response?.data?.message ?? "Update Failed"}</div>
          </div>
        ),
        okText: "OK",
        centered: true,
        okButtonProps: { className: "modal-ok-button" },
        onOk() {},
      });
    }
  };

  return (
    <div className="account-card">
      <Row gutter={16}>
        <Col className="gutter-row" span={24}>
          <div className="cardcontainer">
            <div className="header">
              <h6>Account</h6>
              {/* <div className='button-group'>
                  <Button type='primary' className='custom-secondary-button'>Edit</Button>
                  <Button type='primary' className='custom-primary-button'>Save</Button>
                </div> */}
            </div>
            <Divider />
            <Row className="formcontainer">
              <Col span={24}>
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <div className="input-container">
                      <label> Login Id</label>
                      <CopyableInput value={id} />
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <div className="input-container">
                      <label> MT5 Server</label>
                      <Input
                        placeholder="Enter your Email Address"
                        className="input-text"
                        value={server}
                        readOnly
                      />
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    {modalContextHolder}
                    <div className="input-container">
                      {/* <label className='d-flex'>Invertor Password <Link className='ms-auto primary-color' href={'/reset-password'}>Reset Password</Link></label> */}
                      <label className="d-flex">
                        Investor Password{" "}
                        <Link
                          className="ms-auto primary-color"
                          onClick={() => resetInvestorPassword()}
                        >
                          Reset Password
                        </Link>
                      </label>
                      <Input.Password
                        placeholder="Password"
                        className="input-text"
                        value={investorPassword}
                        onChange={handleInvestorPasswordChange}
                      />
                      {investorPasswordError && (
                        <div className="error-message">
                          {investorPasswordError}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <div className="input-container">
                      <label className="d-flex">
                        Master Password{" "}
                        <Link
                          className="ms-auto primary-color"
                          onClick={() => resetMasterPassword()}
                        >
                          Reset Password
                        </Link>
                      </label>
                      <Input.Password
                        placeholder="Password"
                        className="input-text"
                        id="MasterPassword"
                        value={masterPassword}
                        onChange={handleMasterPasswordChange}
                      />
                      {masterPasswordError && (
                        <div className="error-message">
                          {masterPasswordError}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AccountCard;
