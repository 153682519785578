import { Bars3Icon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { Menu, Transition } from "@headlessui/react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "Components/ui/tooltip";

import { Button } from "Components/ui/button";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useAuth } from "context/AuthContext";
import { useNavigate } from "react-router-dom";

export default function Header({ setSidebarOpen }) {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  return (
    <div className="sticky top-0 z-30 flex h-20 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
      <button
        type="button"
        className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <i className="fa-regular fa-bars w-6 h-6"></i>
      </button>
      <div className="relative flex flex-1">
        <div className="flex h-16 shrink-0 items-center">
          <Link to="/" className="hidden sm:block">
            <img
              className="h-12 w-auto"
              src="/images/logo.png"
              alt="Your Company"
            />
          </Link>
          <Link to="/" className="sm:hidden">
            <img
              className="h-8 w-auto sm:hidden"
              src="/images/tlogo.png"
              alt="Your Company"
            />
          </Link>
        </div>
      </div>
      <div className="flex items-center gap-x-4 lg:gap-x-6 ">
        <Link to="/challenge/purchase">
          <Button className="px-8 py-3 bg-[#FCE8EC] text-[#dc0844] rounded-xl text-xs font-medium shadow-none hover:bg-[#f6d6dc] hidden sm:block">
            Trade Now
          </Button>
        </Link>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Link to="/challenge/purchase">
                <Button
                  className="bg-[#FCE8EC] rounded-full sm:hidden shadow-none hover:bg-[#f6d6dc]"
                  variant="ghost"
                  size="icon"
                >
                  <i className="fa-regular fa-display-chart-up text-[#dc0844]"></i>
                </Button>
              </Link>
            </TooltipTrigger>
            <TooltipContent>
              <p>Trade Now</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <div className="flex items-center gap-x-4 lg:gap-x-6">
        <Menu as="div" className="relative">
          <Menu.Button className="-m-1.5 flex items-center p-1.5">
            <span className="sr-only">Open user menu</span>
            <img
              className="h-9 w-9 rounded-full bg-gray-50"
              src="https://images.unsplash.com/photo-1504714146340-959ca07e1f38?q=80&w=3400&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
            />
            <span className="hidden lg:flex lg:items-center">
              <span
                className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                aria-hidden="true"
              >
                {currentUser?.name}
              </span>
              <ChevronDownIcon
                className="ml-2 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="px-4 py-3">
                <p className="text-sm">Signed in as</p>
                <p className="truncate text-sm font-medium text-gray-900">
                  {currentUser?.email}
                </p>
              </div>
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <Link to="/profile">
                      <a
                        href="#"
                        className={classNames(
                          active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                      >
                        <i className="fa-regular fa-user mr-3"></i>
                        My Profile
                      </a>
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link to="/challenge/purchase">
                      <a
                        href="#"
                        className={classNames(
                          active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                      >
                        <i className="fa-regular fa-receipt mr-3"></i>
                        Billing
                      </a>
                    </Link>
                  )}
                </Menu.Item>
              </div>
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block w-full px-4 py-2 text-left text-sm"
                      )}
                      onClick={() => {
                        logout();
                        navigate("/auth/signin");
                      }}
                    >
                      <i className="fa-regular fa-right-from-bracket mr-3"></i>
                      Logout
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}
