import {
  DollarCircleOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  UserOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  WindowsFilled,
  AppleFilled,
  AndroidFilled,
  DesktopOutlined,
} from "@ant-design/icons";

import {Form, Card, Space, Statistic, Table, Typography,Radio,Row,Col,Button,Image ,Input,Select, Divider,Checkbox} from "antd";
import { useEffect, useState } from "react";
import PageHeader from "../../Components/common/pageHeader";
import PackageCard from "../../Components/Cards/packageCard";

const options = [
  {
    label: 'Connect To Account',
    value: 'connect',
  },
  {
    label: 'Open Demo Account',
    value: 'demoAccount',
  },
  {
    label: 'Open Real Account',
    value: 'realAccount',
  },
];

function TradingPlatform() {
  
  const [value4, setValue4] = useState('connect');
  const onChange4 = ({ target: { value } }) => {
    console.log('radio4 checked', value);
    setValue4(value);
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showNewTradingPlatform, setShowNewTradingPlatform] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onFinish = (values) => {
    console.log('Received values:', values);
    // Add your authentication logic here
  };

  const applicationPlatform = [
    {
      link: 'https://download.mql5.com/cdn/web/tradehall.limited/mt5/tradehalllimited5setup.exe',
      label: 'Tradehall MT5',
      icon: "WindowsFilled", 
      title: "Windows",
    },
    {
      link: 'https://download.mql5.com/cdn/mobile/mt5/android?server=TradehallLimited-Demo,TradehallLimited-Main',
      label: 'Tradehall MT5',
      icon: "AndroidFilled", 
      title: "Android",
    },
    {
      link: 'https://download.mql5.com/cdn/mobile/mt5/ios?server=TradehallLimited-Demo,TradehallLimited-Main',
      label: 'Tradehall MT5',
      icon: "AppleFilled", 
      title: "IOS",
    },
  ];

  const webPlatform = [
    {
      link: 'https://webtrader.tradehall.co',
      label: 'Tradehall MT5',
      icon: "DesktopOutlined", 
      title: "Web",
    },
    {
      link: 'https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/MetaTrader5.dmg?utm_source=support.metaquotes.net&utm_campaign=download.mt5.macos',
      label: 'Tradehall MT5',
      icon: "AppleFilled", 
      title: "Mac OS",
    },
  ];
 

  return (
    <>
      <div>
        {!showNewTradingPlatform && (
          <div className="px-[40px] py-[30px]">
            <div className="mb-5">
              <div className="text-[#1D1E25] text-2xl font-bold mb-3">Application Platform</div>
              <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
                {applicationPlatform.map((value, index) => (
                  <div key={index} className="card px-6 py-3 rounded-lg cursor-pointer bg-gradient-to-r from-[#DD0744] to-[#FF9A44] hover:scale-[1.02]  hover:bg-gradient-to-br hover:from-[#FF9A44] hover:to-[#DD0744] transition-all duration-200 ease-in tracking-wide">
                    <a href={value.link} target="_blank">
                      <div className="flex justify-between items-start mb-2">
                        <div className="text-white text-[14px] mt-2">{value.label}</div>
                        <div>
                          {value.icon === "WindowsFilled" && <WindowsFilled className="text-[3rem] text-white" />}
                          {value.icon === "AppleFilled" && <AppleFilled className="text-[3rem] text-white" />}
                          {value.icon === "AndroidFilled" && <AndroidFilled className="text-[3rem] text-white" />}
                        </div>
                      </div>
                      <div className="flex justify-end items-center">
                        <div class="text-white text-[30px]">{value.title}</div>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
            <div className="mb-5">
              <div className="text-[#1D1E25] text-2xl font-bold mb-3">Web Platform</div>
              <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
                {webPlatform.map((value, index) => (
                  <div key={index} className="card px-6 py-3 rounded-lg cursor-pointer bg-gradient-to-r from-[#DD0744] to-[#FF9A44] hover:scale-[1.02]  hover:bg-gradient-to-br hover:from-[#FF9A44] hover:to-[#DD0744] transition-all duration-200 ease-in tracking-wide">
                    <a href={value.link} target="_blank">
                      <div className="flex justify-between items-start mb-2">
                        <div className="text-white text-[14px] mt-2">{value.label}</div>
                        <div> 
                          {value.icon === "DesktopOutlined" && <DesktopOutlined className="text-[3rem] text-white" />}
                          {value.icon === "AppleFilled" && <AppleFilled className="text-[3rem] text-white" />}
                        </div>
                      </div>
                      <div className="flex justify-end items-center">
                        <div class="text-white text-[30px]">{value.title}</div>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {showNewTradingPlatform && (
          <div className="TradingPlatform-page">
              <div className="page-container">
                <div className="header">
                    <Radio.Group
                    options={options}
                    onChange={onChange4}
                    value={value4}
                    optionType="button"
                    buttonStyle="solid"
                    className="customradio"
                  />
              </div>
            <div className="account-container">
                <Row gutter={16}>
                  <Col span={24}>
                    <h6 >Trading Account</h6>
                  </Col>
                  <Divider/>
                  <Col span={24} className="login-container">
                      <Form name="normal_login" onFinish={onFinish} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                        <Form.Item
                          name="email"
                          label="Login"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your Email!',
                            },
                          ]}
                        >
                          <Input
                            placeholder="Email"
                            className='input-text mb-0'
                          />
                        </Form.Item>
                        <Form.Item
                          name="password"
                          label={<div className="custom-label">
                          <div>Password </div>
                          <div className="forgot-password">Forgot Password? <a href="#"> Contact Company</a></div>
                        </div>}
                          rules={[
                            {
                              required: true,
                              message: 'Please input your Password!',
                            },
                          ]}
                        >
                          <Input
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Password"
                            suffix={
                              <span
                                onClick={handlePasswordVisibility}
                                className="password-toggle"
                              >
                                {showPassword ? (
                                  <EyeOutlined />
                                ) : (
                                  <EyeInvisibleOutlined />
                                )}
                              </span>
                            }
                          />
                        </Form.Item>
                
                          <Form.Item  name="remember" valuePropName="checked" noStyle>
                            <Checkbox>Save Password</Checkbox>
                          </Form.Item>
                        <Form.Item>
                          <Button type="primary" htmlType="submit" className="custom-primary-button w-100 mt-4">
                            Contact To Account
                          </Button>
                        </Form.Item>          
                      </Form>
                  </Col>
                </Row>
            </div>

              </div>
          </div>
        )}
      </div>
    </>
  );
}


export default TradingPlatform;
