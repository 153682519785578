// Header.js

import React from 'react';
import { Row,Col,Image,Dropdown,Divider} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './index.scss'; // Import the custom styles





const TradinginfoCard = ({heading,status,profit,description,value,lossValue,lossDescription}) => {
  return (
    <div className='trading-info-card'>
     <div className="header">
      <h1>{heading}</h1>
      <div className={`status-${status} flex justify-center items-center`}>
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
          <circle cx="4" cy="4" r="4" fill={profit ? 'green' : '#DE0C44'} />
        </svg>
        <div className='uppercase text-[.68rem]'>{status}</div>
      </div>
     </div>
     <Divider/>
     <div className='inner'>
      <div className="inner-container">
        <p className="value">{value}</p>
        <p className="description">{description}</p>
      </div>
      <div className="inner-container">
        <p className="loss">{lossValue}</p>
        <p className="description">{lossDescription}</p>
      </div>
     </div>
    
    </div>

  );
};

export default TradinginfoCard;
