import React, { useEffect } from 'react';
import { Chart } from 'chart.js/auto';

const AnalyticsLineChart = ({ detailStats, tradingInfoData }) => {
  useEffect(() => {
    const data = {
      //labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      labels: detailStats.map((x) => x.displayDate),
      datasets: [{
        label: 'Analytics Data',
        data: detailStats.map((x) => parseFloat(x.equity)),
        borderColor: '#DD0744', // Line color
        borderWidth: 3,
        fill: true,
        backgroundColor: (ctx) => {
          const gradient = ctx.chart.ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0, 'rgba(221, 7, 68, 0.50)');
          gradient.addColorStop(1, 'rgba(255, 154, 68, 0.00)'); 
          return gradient;
        },
      }],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          type: 'category',
        },
        y: {
          beginAtZero: true,
        },
      },
    };

    const ctx = document.getElementById('analyticsLineChart').getContext('2d');

    const myLineChart = new Chart(ctx, {
      type: 'line',
      data: data,
      options: options,
    });

    return () => {
      myLineChart.destroy();
    };
  }, [detailStats]);

  return (
    <div>
      <canvas id="analyticsLineChart" width="400" height="441"></canvas>
    </div>
  );
};

export default AnalyticsLineChart;
