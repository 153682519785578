// Header.js

import React from 'react';
import { Row,Col,Image,Dropdown} from 'antd';
import { useNavigate } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import './index.scss'; // Import the custom styles


const items = [
  {
    label: <a href="https://www.antgroup.com">1st menu item</a>,
    key: '0',
  },
  {
    label: <a href="https://www.aliyun.com">2nd menu item</a>,
    key: '1',
  },
 
  
];


const PackageCard = ({image,name,price,id,endDate,server,phase,active}) => {
  const navigate = useNavigate(); // Initialize the useHistory hook

  const handleArrowClick = () => {
    navigate(`/matrix/${id}`);
  };
  const serverName = server == 1 ? 'TradehallLimited-Demo' : 'TradehallLimited-Main';
  return (
    <div className={`packageCard cursor-pointer ${active ? 'active-package' : ''}`}  onClick={handleArrowClick}>
       <Row gutter={16} >
        <Col className="gutter-row" span={24}>
          <div className="cardcontainer">
              <div className='intertnal'>
                  {/* <div className="imagesSection">
                    <Image src={image} alt={"icon"} />
                  </div> */}
                  <div className="w-[100px] h-[100px] rounded-lg bg-red-300 bg-opacity-10 hidden items-center justify-center md:flex">
                    <Image src={image} alt={"icon"} className='object-contain'/>
                  </div>

                  <div className="pkgdetail">
                    {/* <div className="packageInfo">
                      <h1>{name}</h1>
                      <div className="badge">
                        {price}
                      </div>
                      <Dropdown
                        menu={{
                          items,
                        }}
                        trigger={['click']}
                      >
                        <a onClick={(e) => e.preventDefault()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                          <path d="M12 20.5C11.45 20.5 10.9792 20.3042 10.5875 19.9125C10.1958 19.5208 10 19.05 10 18.5C10 17.95 10.1958 17.4792 10.5875 17.0875C10.9792 16.6958 11.45 16.5 12 16.5C12.55 16.5 13.0208 16.6958 13.4125 17.0875C13.8042 17.4792 14 17.95 14 18.5C14 19.05 13.8042 19.5208 13.4125 19.9125C13.0208 20.3042 12.55 20.5 12 20.5ZM12 14.5C11.45 14.5 10.9792 14.3042 10.5875 13.9125C10.1958 13.5208 10 13.05 10 12.5C10 11.95 10.1958 11.4792 10.5875 11.0875C10.9792 10.6958 11.45 10.5 12 10.5C12.55 10.5 13.0208 10.6958 13.4125 11.0875C13.8042 11.4792 14 11.95 14 12.5C14 13.05 13.8042 13.5208 13.4125 13.9125C13.0208 14.3042 12.55 14.5 12 14.5ZM12 8.5C11.45 8.5 10.9792 8.30417 10.5875 7.9125C10.1958 7.52083 10 7.05 10 6.5C10 5.95 10.1958 5.47917 10.5875 5.0875C10.9792 4.69583 11.45 4.5 12 4.5C12.55 4.5 13.0208 4.69583 13.4125 5.0875C13.8042 5.47917 14 5.95 14 6.5C14 7.05 13.8042 7.52083 13.4125 7.9125C13.0208 8.30417 12.55 8.5 12 8.5Z" fill="#DD0744"/>
                        </svg>
                        </a>
                      </Dropdown>
                    </div> */}

                    <div className="flex gap-3 flex-col sm:flex-row mb-[0.85rem] text-black">
                      <h1 className={`${active ? 'text-[#FFF]' : ''} text-[16px] md:text-[20px] font-semibold`}>{name}</h1>
                      <div className={`${active ? 'text-[#FFF]' : 'text-[#DD0744] bg-opacity-10'} w-fit text-[14px] bg-[#DE0C44] font-medium rounded-[30px] py-[5px] px-[15px] flex justify-center items-center`}>
                        {price}
                      </div>
                    </div>
                    
                    {/* Package Essentials */}
                    {/* <div className='packageEssentials'>
                      <div className='info'>
                        <p className='heading'>User Id</p>
                        <p className='detail'>{id}</p>
                      </div>
                      
                      <div className='info'>
                        <p className='heading'>Phase</p>
                        <p className='detail'>{phase}</p>
                      </div>
                      
                      <div className='info'>
                        <p className='heading'>Package End Date</p>
                        <p className='detail'>{endDate}</p>
                      </div>
                      
                      <div className='info'>
                        <p className='heading'>MT5 Server Name</p>
                        <p className='detail'>{server}</p>
                      </div>  
                    </div> */}
                    <div className='grid gap-3 grid-cols-[1fr] sm:grid-cols-[1fr,1fr] xl:grid-cols-[1fr,1fr,1fr,1fr] mr-4'>
                      <div className='info'>
                        <p className={`${active ? 'text-[#FFF] opacity-70' : 'text-[#717184]'} font-inter text-[14px]  leading-[25px]`}>MT5 Id</p>
                        <p className={`${active ? 'text-[#FFF]' : 'text-[#000]'} font-inter text-base leading-[25px]`}>{id}</p>
                      </div>
                      
                      <div className='info'>
                        <p className={`${active ? 'text-[#FFF] opacity-70' : 'text-[#717184]'} font-inter text-[14px] leading-[25px]`}>Phase</p>
                        <p className={`${active ? 'text-[#FFF]' : 'text-[#000]'} font-inter text-base leading-[25px]`}>{phase}</p>
                      </div>
                      
                      <div className='info'>
                        <p className={`${active ? 'text-[#FFF] opacity-70' : 'text-[#717184]'} font-inter text-[14px]  leading-[25px]`}>Package End Date</p>
                        <p className={`${active ? 'text-[#FFF]' : 'text-[#000]'} font-inter text-base leading-[25px]`}>{endDate}</p>
                      </div>
                      
                      <div className='info'>
                        <p className={`${active ? 'text-[#FFF] opacity-70' : 'text-[#717184]'}  font-inter text-[14px]  leading-[25px]`}>MT5 Server Name</p>
                        <p className={`${active ? 'text-[#FFF]' : 'text-[#000]'} font-inter text-base leading-[25px]`}>{serverName}</p>
                      </div>  
                    </div>

                  </div>
                  
                </div>
                
                {/* <div className='linkArrow ' style={{cursor: 'pointer'}} onClick={handleArrowClick}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M14.4302 5.93018L20.5002 12.0002L14.4302 18.0702" stroke="#DE0C44" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M3.5 12H20.33" stroke="#DE0C44" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div> */}
              
                <div 
                  className={`h-auto rounded-r-[10px] ${active ? "bg-[#000]" : "bg-[#DE0C44]"} bg-opacity-10 hidden sm:flex items-center justify-center max-w-[44px] min-w-[44px]`} 
                 
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M14.4302 5.93018L20.5002 12.0002L14.4302 18.0702" stroke={`${active ? '#fff' : '#DE0C44'}`} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M3.5 12H20.33" stroke={`${active ? '#fff' : '#DE0C44'}`} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
          </div>
          
        </Col>
      </Row>
        
    </div>
  );
};

export default PackageCard;
